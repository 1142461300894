export interface Badge {
    moduleId: string,
    badgeId: string,
    title: string,
    instruction?: string | undefined,
    estimatedTime?: number | undefined,
    active: boolean,
    sequence: number,
    badgeFolder?: string | undefined,
    hasStudentWork?: boolean | undefined,
    allowResubmit?: boolean | undefined,
    acceptanceCriteria?: string | undefined
}

export const badgeDisplayName = (badge: Badge) => {
    return `${badge.badgeId} - ${badge.title}`
}