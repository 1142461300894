import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { columns } from "./badge-list-column-definition";
import { Badge } from "../../../model/badge";
import { getBadges } from "../../../service/badge-service";
import { EDUCATION_MANAGEMENT_MODULE_BADGE_ADD, EDUCATION_MANAGEMENT_MODULE_BADGE_EDIT, PARAMETER_BADGE_ID, PARAMETER_MODULE_ID } from "../../../../../infrastructure/route";
import { Module } from "../../../model/module";
import { BADGE_TAB_STATE } from "../badge-view";
import { useCache } from "../../../../../context/cache/cache-provider";
import { BaseList } from "../../../../../component/list/base-list";

export default function BadgeList({ module }: { module: Module }) {
    const [loading, setLoading] = useState(true);
    const [badges, setBadges] = useState<Badge[]>([]);

    const navigate = useNavigate();
    const cache = useCache();

    useEffect(() => {
        setLoading(true);
        getBadges(module.moduleId).then(result => {
            setBadges(result ?? []);
            setLoading(false);
        });
    }, [module.moduleId]);

    const openEdit = (badgeId: string) => {
        const badge = badges.find(_ => _.badgeId === badgeId);
        cache.update(
            { key: PARAMETER_MODULE_ID, value: module.title },
            { key: PARAMETER_BADGE_ID, value: badge?.title ?? '' }
        );

        localStorage.removeItem(BADGE_TAB_STATE);
        navigate(EDUCATION_MANAGEMENT_MODULE_BADGE_EDIT
            .replace(PARAMETER_MODULE_ID, `${module.moduleId}`)
            .replace(PARAMETER_BADGE_ID, `${badgeId}`), { state: badges })
    }

    const openAdd = () => {
        localStorage.removeItem(BADGE_TAB_STATE);
        navigate(EDUCATION_MANAGEMENT_MODULE_BADGE_ADD
            .replace(PARAMETER_MODULE_ID, `${module.moduleId}`), { state: badges });
    }

    return (
        <BaseList
            storageKey="BADGE"
            rows={badges}
            columns={columns}
            loading={loading}
            localeText={{ noRowsLabel: "No badges" }}
            getRowId={(row) => row?.badgeId}
            onRowClick={(e) => openEdit(e.row?.badgeId)}
            onAddClick={() => openAdd()}
            addButtonText="Add badge"
        />
    );
}