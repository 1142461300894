export enum SurveyAnswerType {
    Text = 1,
    Text_Area = 2,
    Select = 3,
    Multi_Select = 4,
    Radio = 5,
    Boolean = 6,
    Date = 7
}

export const answerTypes = (Object.values(SurveyAnswerType).filter((v) => !isNaN(Number(v))) as number[]);

export const getAnswerTypeName = (answerType: SurveyAnswerType) => {
    switch (answerType) {
        case SurveyAnswerType.Text:
            return "Text field, one line"
        case SurveyAnswerType.Text_Area:
            return "Text fields, multiple lines"
        case SurveyAnswerType.Select:
            return "Select one option, dropdown menu"
        case SurveyAnswerType.Multi_Select:
            return "Select multiple options, dropdown menu"
        case SurveyAnswerType.Radio:
            return "Select one option, radio buttons"
        case SurveyAnswerType.Boolean:
            return "Select one option, checkbox"
        case SurveyAnswerType.Date:
            return "Select date"
        default:
            return SurveyAnswerType[answerType].replace('_', ' ')
    }
}