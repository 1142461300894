import { courseLevelOptions, moduleCategoryOptions } from "../../../../utils/form-select-input-options";
import { FieldDefinition } from "../../../form/field-definition";
import { yesNoOptions } from "../../enum/yes-no";

export const moduleMultiSelectFilterFieldDefinitions: FieldDefinition[] = [
    {
        id: "modulesSelected",
        name: "Selected modules",
        type: "select",
        required: false,
        disabled: false,
        values: yesNoOptions()
    },
    {
        id: "courseLevel",
        name: "Course level",
        type: "select",
        required: false,
        disabled: false,
        values: courseLevelOptions(true)
    },
    {
        id: "moduleCategory",
        name: "Module category",
        type: "select",
        required: false,
        disabled: false,
        values: moduleCategoryOptions(true)
    },
    {
        id: "moduleSearch",
        name: "Module search",
        type: "text",
        required: false,
        disabled: false
    }
];