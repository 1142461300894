import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ViewLoader from "../../../component/misc/view-loader";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import styles from "../style/survey.module.css"
import { useCache } from "../../../context/cache/cache-provider";
import { EXAMINE_SURVEY_SUBMIT_LIST, PARAMETER_SURVEY_SUBMIT_ID } from "../../../infrastructure/route";
import { SurveySubmit } from "../model/survey-submit";
import { acceptSurveySubmit, deleteSurveySubmit, getSurveySubmit } from "../service/survey-submit-service";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import BaseOperations from "../../../component/operations/base-operations";
import { DialogType, informationText } from "../../../context/dialog/dialog-context-type";
import { useToast } from "../../../context/toast/toast-provider";
import { useDialog } from "../../../context/dialog/dialog-provider";
import { SurveySubmitAccept } from "../model/survey-submit-accept";
import { surveySubmitAcceptFieldDefinitions } from "./survey-submit-accept-field-defenition";
import BaseForm from "../../../component/form/base-form";
import { SelectItem } from "../../../component/form/select-item";
import { Role, studentRoles } from "../../enum/role";
import { SurveySubmitStatus } from "../enum/survey-submit-status";
import { ApiErrorType } from "../../../utils/api/api-error-type";

dayjs.extend(utc)
dayjs.extend(tz)

export const SURVEY_SUBMIT_TAB_STATE: string = "SURVEY_SUBMIT_TAB_STATE";
export const SURVEY_SUBMIT_TAB_STATE_VIEW: string = "SURVEY_SUBMIT_TAB_STATE_VIEW";

export default function SurveySubmitView() {
    const { surveySubmitId } = useParams();

    const [tab, setTab] = useState<string>(localStorage.getItem(SURVEY_SUBMIT_TAB_STATE) ?? SURVEY_SUBMIT_TAB_STATE_VIEW);
    const [loading, setLoading] = useState<boolean>(true);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [accepting, setAccepting] = useState<boolean>(false);
    const [surveySubmit, setSurveySubmit] = useState<SurveySubmit | undefined>();
    const [surveySubmitAccept, setSurveySubmitAccept] = useState<SurveySubmitAccept>({acceptedRoles: []});
    const [surveySubmitValidationErrors, setSurveySubmitValidationErrors] = useState<any | undefined>([]);

    const cache = useCache();
    const toast = useToast();
    const navigate = useNavigate();
    const dialog = useDialog();

    useEffect(() => {
        cache.update({ key: PARAMETER_SURVEY_SUBMIT_ID, value: surveySubmit?.surveyTitle ?? '' });
    }, [cache, surveySubmit]);

    useEffect(() => {
        setLoading(true);
        getSurveySubmit(Number(surveySubmitId)).then(result => {
            setSurveySubmit(result);
            setLoading(false);
        })
    }, [surveySubmitId]);

    const changeTab = (newTab: string) => {
        localStorage.setItem(SURVEY_SUBMIT_TAB_STATE, newTab);
        setTab(newTab);
    }

    const getSubmitDate = (submitDate?: string | undefined) => {
        const timeZone = dayjs.tz.guess();
        const local = dayjs.utc(submitDate).tz(timeZone);
        return dayjs(local).format('YYYY-MM-DD HH:mm:ss');
    }

    const getSurveySubmitAcceptFieldDefinitions = () => {
        if (surveySubmit?.acceptRoles !== undefined && surveySubmit.acceptRoles.length > 0) {

            const acceptSelectItems = surveySubmit?.acceptRoles.map(_ => {
                let selectItem: SelectItem = {
                    id: _,
                    name: Role[_]
                }
                return selectItem;
            });

            surveySubmitAcceptFieldDefinitions.find(_ => _.id === "acceptedRoles")!.values = acceptSelectItems;
        }
        
        return surveySubmitAcceptFieldDefinitions;
    }

    const validateAccept = () => {
        var errors = {};
        var isValid = true;
        var studentRolesAccept = surveySubmitAccept.acceptedRoles.filter(_ => studentRoles.find(sr => sr === _) !== undefined);
        if (studentRolesAccept.length > 1) {
            errors = { ...errors, acceptedRoles: `You can only select one stundet role` };
            isValid = false;
        }

        setSurveySubmitValidationErrors(errors)
        return isValid;
    }

    const getAcceptConfirmationtText = () => {
        if (surveySubmit?.acceptRoles !== undefined && 
            surveySubmit.acceptRoles.length === 0
        ) {
            return [informationText(`Are you sure you want to accept this submit for survey <b>${surveySubmit?.surveyTitle}</b>?`)];
        } else if (
            surveySubmit?.acceptRoles !== undefined && 
            surveySubmit.acceptRoles.length > 0 && 
            surveySubmitAccept.acceptedRoles.length === 0
        ) {
            return [informationText(`Are you sure you want to accept this submit for survey <b>${surveySubmit?.surveyTitle}</b> with no roles selected?`)];
        } else {
            const roles = surveySubmitAccept.acceptedRoles.map(_ => Role[_]).join(", ");
            return [informationText(`Are you sure you want to accept this submit for survey <b>${surveySubmit?.surveyTitle}</b> with the roles <b>${roles}</b>?`)];
        }
    }

    const handleAccept = async () => {
        const choice = await dialog.openDialog(DialogType.CONFIRM, "Are you sure?", getAcceptConfirmationtText());
        if (choice === true && surveySubmit?.id !== undefined && surveySubmit?.actorId !== undefined) {
            setAccepting(true);
            acceptSurveySubmit(surveySubmit.id, surveySubmit.actorId, surveySubmitAccept.acceptedRoles).then(() => {
                toast.addToast(`Survey submit for ${surveySubmit.surveyTitle} accepted`, "success");
                setAccepting(false);
                navigate(EXAMINE_SURVEY_SUBMIT_LIST);
            }).catch((error) => {
                if (error?.apiErrorResponse?.errorType !== undefined && error?.apiErrorResponse?.errorType === ApiErrorType.Survey_InActive) {
                    toast.addToast(`Unable to accept submit form survey ${surveySubmit.surveyTitle}, it is inactive`, "error");
                } else {
                    toast.addToast(`Unable to accept submit form survey ${surveySubmit.surveyTitle}`, "error");
                }
                
                setAccepting(false);
            });
        }
    }

    const handleDelete = () => {
        if (surveySubmit?.id !== undefined) {
            setDeleting(true);
            deleteSurveySubmit(surveySubmit.id).then(() => {
                toast.addToast(`Survey submit for ${surveySubmit.surveyTitle} deleted`, "success");
                setDeleting(false);
                navigate(EXAMINE_SURVEY_SUBMIT_LIST);
            }).catch(() => {
                toast.addToast(`Unable to delete submit form survey ${surveySubmit.surveyTitle}`, "error");
                setDeleting(false);
            });
        }
    }

    return (
        <React.Fragment>
            <ViewLoader loading={loading} />
            {loading === false && <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(_: React.SyntheticEvent, newTab: string) => changeTab(newTab)}>
                        <Tab label="Submit" value={SURVEY_SUBMIT_TAB_STATE_VIEW} />
                    </TabList>
                </Box>
                <TabPanel value={SURVEY_SUBMIT_TAB_STATE_VIEW}>
                    {surveySubmit?.id !== undefined && 
                    surveySubmit.status === SurveySubmitStatus.Pending && 
                    surveySubmit?.actorId !== undefined && <div className={styles.surveySubmitAccept}>
                        {surveySubmit?.acceptRoles !== undefined && surveySubmit.acceptRoles.length > 0 && <BaseForm
                            name="Accept roles"
                            model={surveySubmitAccept}
                            setModel={setSurveySubmitAccept}
                            fields={getSurveySubmitAcceptFieldDefinitions()}
                            knownValidationErrors={surveySubmitValidationErrors}
                        />}
                        <LoadingButton
                            variant="contained"
                            startIcon={<DoneIcon />}
                            disabled={deleting}
                            loading={accepting}
                            onClick={() => {
                                const isValid = validateAccept();
                                if (isValid === true) {
                                    handleAccept();
                                }
                            }}
                        >
                            Accept
                        </LoadingButton>
                    </div>}
                    {surveySubmit?.id !== undefined && surveySubmit.status === SurveySubmitStatus.Accepted && <h3 className={styles.surveySubmitAccepted}>This submit is already accepted!</h3>}
                    <h2>{surveySubmit?.surveyTitle}</h2>
                    <p><b>{surveySubmit?.name}</b></p>
                    <p><b>{surveySubmit?.phoneNumber}</b></p>
                    <p><b>{surveySubmit?.emailAddress}</b></p>
                    <p><b>{getSubmitDate(surveySubmit?.submitDate)}</b></p>
                    <br />
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: '50%' }}><b>Question</b></TableCell>
                                    <TableCell sx={{ width: '50%' }}><b>Answer</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {surveySubmit?.answers.map((_) => (
                                    <TableRow key={_.questionSequence}>
                                        <TableCell>Q{_.questionSequence}: {_.question}</TableCell>
                                        <TableCell>{_.answer}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />
                    <BaseOperations 
                        action1ButtonText="Delete"
                        action1ButtonIcon={<DeleteIcon />}
                        action1ButtonLoading={deleting}
                        action1ButtonDisable={accepting}
                        action1ButtonConfirmationTexts={[informationText(`Are you sure you want to delete this submit for survey <b>${surveySubmit?.surveyTitle}</b>?`)]}
                        onAction1ButtonClick={handleDelete}
                    />
                </TabPanel>
            </TabContext>}
        </React.Fragment>
    );
}
