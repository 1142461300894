import React, { useEffect, useState } from "react";
import SaveIcon from '@mui/icons-material/Save';
import { User, sortUserList } from "../../../section/-account/model/user";
import { getUsers } from "../../../section/-account/service/user-service";
import BaseMutliSelect from "../base-multi-select";
import ViewLoader from "../../misc/view-loader";
import BaseMultiSelect from "../base-multi-select";
import { UserMultiSelectFilterModel, filterUsers } from "./filter/user-multi-select-filter-model";
import UserMultiSelectFilter from "./filter/user-multi-select-filter";
import { Role } from "../../../section/enum/role";
import BaseOperations from "../../operations/base-operations";

export const UserMultiSelect = (
    props: Omit<React.ComponentProps<typeof BaseMultiSelect>, "classes"> & {
        filter: UserMultiSelectFilterModel,
        setFilter: React.Dispatch<React.SetStateAction<UserMultiSelectFilterModel>>
        userRoles: Role[],
        handleSubmit(): void,
        submitting: boolean
    }
) => {
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState<User[]>(props.items ?? []);

    useEffect(() => {
        if (users.length === 0) {
            setLoading(true);
            getUsers(props.userRoles).then((result) => {
                setUsers(sortUserList(result.filter(_ => _.active === true)));
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, [props.userRoles, users.length]);

    const handleAvailableUserRender = (user: User) => {
        if (props.availableItemRender !== undefined) {
            return props.availableItemRender(user);
        } else {
            return <span key={`${user.id}-available-user`}>{user.name}</span>
        }
    }

    const handleSelectedUserRender = (user: User) => {
        if (props.selectedItemRender !== undefined) {
            return props.selectedItemRender(user);
        } else {
            return <p key={`${user.id}-selected-user`}>{user.name} - ({user.email})</p>
        }
    }

    return (
        <React.Fragment>
            <ViewLoader loading={loading} />
            {loading === false && <UserMultiSelectFilter
                filter={props.filter}
                setFilter={props.setFilter}
            />}
            {loading === false && <BaseMutliSelect
                {...props}
                items={filterUsers(props.filter, users, props.selectedItems)}
                itemIdKey='id'

                selectedItemsTitle={props.selectedItemsTitle ?? (props.selectedItems.length > 0 ? `Selected ${props.selectedItems.length} users(s)` : 'Selected users(s)')}
                selectedItemsNoneText={props.selectedItemsNoneText ?? 'No users selected'}
                selectedItemRender={(e) => handleSelectedUserRender(e as User)}

                availableItemsTitle={props.availableItemsTitle ?? 'Available users(s)'}
                availableItemsNoneText={props.availableItemsNoneText ?? 'No users available'}
                availableItemRender={(e) => handleAvailableUserRender(e as User)}

                disabled={props.submitting}
            />}
            {loading === false && <BaseOperations
                primaryButtonText="Save"
                primaryButtonIcon={<SaveIcon />}
                onPrimaryButtonClick={() => props.handleSubmit()}
                primaryButtonLoading={props.submitting}
                helpInfoTexts={[
                    "If you can't find the user you are looking for, make sure that he or she has the role <b>Student</b> and is <b>Active</b>. Ask the CohortCaptain, or Administrator if you need help.",
                    "When you <b>select a Student</b> to assign him of her to the cohort you could get a <b>warning</b> if there are any diviations. The student is still assignable, you just need to be aware."
                ]}
            />}
        </React.Fragment>
    );
}
