import { HttpMethod, requestOptions } from "../../../utils/api/request-options";
import { handleResponse, handleResponseNoContent } from "../../../utils/api/response-handler";
import { Presentation } from "../model/presentation";

export function getPresentations(from: string, to: string): Promise<Presentation[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/presentation/list/${from}/${to}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<Presentation[]>(response)
        });
}

export function getPresentation(presenterId: number, day: string): Promise<Presentation> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/presentation/${presenterId}/${day}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<Presentation>(response)
        });
}

export function addPresentation(presentation: Presentation): Promise<Presentation> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/presentation`, requestOptions(HttpMethod.POST, presentation))
        .then(response => {
            return handleResponse<Presentation>(response)
        });
}

export function updatePresentation(presentation: Presentation): Promise<Presentation> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/presentation`, requestOptions(HttpMethod.PUT, presentation))
        .then(response => {
            return handleResponse<Presentation>(response)
        });
}

export function deletePresentation(presenterId: number, day: string): Promise<void> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/presentation/${presenterId}/${day}`, requestOptions(HttpMethod.DELETE))
        .then(response => {
            return handleResponseNoContent(response)
        });
}