import { requestOptions, HttpMethod } from "../../../utils/api/request-options";
import { handleResponse, handleResponseNoContent } from "../../../utils/api/response-handler";
import { Role } from "../../enum/role";
import { SurveySubmit } from "../model/survey-submit";
import { SurveySubmitOverview } from "../model/survey-submit-overview";

export function getSurveySubmits(from: string, to: string): Promise<SurveySubmitOverview[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/survey/submit/list/${from}/${to}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<SurveySubmitOverview[]>(response)
        });
}

export function getUserSurveySubmits(userId: number): Promise<SurveySubmitOverview[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/survey/submit/actor/${userId}/list/`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<SurveySubmitOverview[]>(response)
        });
}

export function getSurveySubmit(surveySubmitId: number): Promise<SurveySubmit> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/survey/submit/${surveySubmitId}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<SurveySubmit>(response)
        });
}

export function deleteSurveySubmit(surveySubmitId: number): Promise<void> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/survey/submit/${surveySubmitId}`, requestOptions(HttpMethod.DELETE))
        .then(response => {
            return handleResponseNoContent(response)
        });
}

export function acceptSurveySubmit(surveySubmitId: number, userId: number, roles?: Role[]): Promise<void> {
    var rolesQuery = roles?.map(_ => `roles=${_}`).join('&');
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/survey/submit/${surveySubmitId}/accept/${userId}?${rolesQuery ?? ''}`, requestOptions(HttpMethod.POST))
        .then(response => {
            return handleResponseNoContent(response)
        });
}