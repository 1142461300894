import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth/auth-provider";
import logo from "../assets/learning_lions_logo.png";
import styles from "./style/login.module.css";
import { LinearProgress } from "@mui/material";
import { useToast } from "../context/toast/toast-provider";
import { LMS_LOGOUT_INFO, LMS_PAGE_LOGOUT_RELOAD } from "../component/constant";
import { HOME } from "../infrastructure/route";
import GoogleAuth from "../auth/google-auth";

export default function Login() {
    const [loading, setLoading] = useState<boolean>(true);

    const location = useLocation();
    const navigate = useNavigate();
    const auth = useAuth();
    const toast = useToast();

    useEffect(() => {
        if (auth.user !== null) {
            navigate(HOME)
        }
    }, [auth.user, navigate])

    useEffect(() => {
        const pageReload = sessionStorage.getItem(LMS_PAGE_LOGOUT_RELOAD);
        if (pageReload === 'true') {
            sessionStorage.removeItem(LMS_PAGE_LOGOUT_RELOAD);
            window.location.reload();
        } else {
            const logoutInfo = sessionStorage.getItem(LMS_LOGOUT_INFO);
            if (logoutInfo) {
                sessionStorage.removeItem(LMS_LOGOUT_INFO);
                toast.addToast(logoutInfo, 'warning');
            }
            setLoading(false);
        }
    }, [toast])

    const authSuccessful = (accessToken: string) => {
        if (!auth.loading) {
            const from = location.state?.from?.pathname + location.state?.from?.search || "/";
            auth.signIn(accessToken, from);
        }
    }

    return (
        <React.Fragment>
            {loading === false && <div className={styles.prompt}>
                <img style={{ width: '100%' }} id="ll-logo" src={logo} alt="Learning lions logo" />
                <div>
                    <h1 style={{ textAlign: 'center' }} >LMS v3 - Login</h1>
                    {!auth.loading && <GoogleAuth authSuccessful={authSuccessful} />}
                    {auth.loading && <LinearProgress />}
                </div>
            </div>}
        </React.Fragment>
    );
}
