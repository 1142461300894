import { CourseLevel } from "../../-education/enum/course-level"
import { ModuleAssignment } from "../model/module-assignment";

export const getGeneralCourseLevel = (moduleAssignments: ModuleAssignment[]) => {
    const mobilLeaps = moduleAssignments.filter(_ => _.moduleCourseLevelId === CourseLevel.Mobile_Leap);
    const bootCamps = moduleAssignments.filter(_ => _.moduleCourseLevelId === CourseLevel.BootCamp);
    const advanceds = moduleAssignments.filter(_ => _.moduleCourseLevelId === CourseLevel.Advanced);

    return [mobilLeaps, bootCamps, advanceds].sort((a, b) => b.length - a.length)[0][0]?.moduleCourseLevelId ?? CourseLevel.Advanced;
}

export const getModuleCourseLevel = (moduleAssignments: ModuleAssignment[], badgeId: string | undefined) => {
    const module = moduleAssignments.find(_ => _.badges.find(b => b.badgeId === badgeId));
    return module?.moduleCourseLevelId ?? CourseLevel.Advanced;
}

export const getBadgeTerminology = (
    moduleCourseLevel?: CourseLevel | undefined,
    plural?: boolean | undefined
) => {
    let term = "Badge";
    switch(moduleCourseLevel) {
        case CourseLevel.Mobile_Leap: term = "Session"; break;
        case CourseLevel.BootCamp: term = "Badge"; break;
        case CourseLevel.Advanced: term = "Badge"; break;
        default: break;
    }
    return plural === true ? term + "s" : term;
}

export const getModuleTerminology = (
    moduleCourseLevel?: CourseLevel | undefined,
    plural?: boolean | undefined
) => {
    let term = "Module";
    switch(moduleCourseLevel) {
        case CourseLevel.Mobile_Leap: term = "Module"; break;
        case CourseLevel.BootCamp: term = "Module"; break;
        case CourseLevel.Advanced: term = "Module"; break;
        default: break;
    }
    return plural === true ? term + "s" : term;
}

export const getStudentTerminology = (
    moduleCourseLevel?: CourseLevel | undefined,
    plural?: boolean | undefined
) => {
    let term = "Student";
    switch(moduleCourseLevel) {
        case CourseLevel.Mobile_Leap: term = "Explorer"; break;
        case CourseLevel.BootCamp: term = "Student"; break;
        case CourseLevel.Advanced: term = "Student"; break;
        default: break;
    }
    return plural === true ? term + "s" : term;
}