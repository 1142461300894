import { FieldDefinition } from "../../../../component/form/field-definition";

export const surveyFieldDefinitions: FieldDefinition[] = [
    {
        id: "title",
        name: "Title",
        type: "text",
        required: true,
        disabled: false,
    },
    {
        id: "active",
        name: "Active",
        type: "boolean",
        required: true,
        disabled: false
    },
    {
        id: "description",
        name: "Description",
        type: "text-area",
        fullInput: true,
        required: true,
        disabled: false
    },
    {
        id: "accessRoles",
        name: "Access Roles",
        type: "multi-select",
        required: false,
        disabled: false
    },
    {
        id: "acceptRoles",
        name: "Accept Roles",
        type: "multi-select",
        required: false,
        disabled: true
    }
];