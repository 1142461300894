import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { columns } from "./presentation-list-column-definition";
import commonStyles from "../../../style/common.module.css";
import dayjs, { Dayjs } from "dayjs";
import { Presentation } from "../../model/presentation";
import { getPresentations } from "../../service/presentation-service";
import { EDUCATION_PRESENTATION_ADD, EDUCATION_PRESENTATION_EDIT, PARAMETER_PRESENTER_DAY, PARAMETER_PRESENTER_ID } from "../../../../infrastructure/route";
import { useCache } from "../../../../context/cache/cache-provider";
import { BaseList } from "../../../../component/list/base-list";

export default function PresentationList() {
    const [loading, setLoading] = useState(true);
    const [presentations, setPresentations] = useState<Presentation[]>([]);
    const [from, setFrom] = useState<Dayjs | null>(null);
    const [to, setTo] = useState<Dayjs | null>(null);

    const navigate = useNavigate();
    const cache = useCache();

    useEffect(() => {
        if (from !== null && to !== null && to.diff(from, 'day') >= 0) {
            setLoading(true);
            getPresentations(from.format('YYYY-MM-DD'), to.format('YYYY-MM-DD')).then(result => {
                setPresentations(result ?? []);
                setLoading(false);
            });
        }
    }, [from, to]);

    const openEdit = (presenterId: string, day: string) => {
        const presentation = presentations.find(_ => _.presenterId === Number(presenterId) && dayjs(_.day).format('YYYY-MM-DD') === day);
        cache.update(
            { key: PARAMETER_PRESENTER_ID, value: presentation?.presenterName ?? '' },
            { key: PARAMETER_PRESENTER_DAY, value: dayjs(presentation?.day).format('YYYY-MM-DD') }
        );

        navigate(EDUCATION_PRESENTATION_EDIT
            .replace(PARAMETER_PRESENTER_ID, `${presenterId}`)
            .replace(PARAMETER_PRESENTER_DAY, `${day}`));
    }

    const openAdd = () => {
        navigate(EDUCATION_PRESENTATION_ADD);
    }

    return (
        <div className={commonStyles.content} >
            <BaseList
                storageKey="PRESENTATION"
                rows={presentations}
                columns={columns}
                loading={loading}
                localeText={{ noRowsLabel: "No presentations" }}
                getRowId={(row) => `${row?.presenterId}_${row?.day}`}
                onRowClick={(e) => openEdit(e.row?.presenterId, dayjs(e.row?.day).format('YYYY-MM-DD'))}
                onAddClick={() => openAdd()}
                addButtonText="Update presentation"
                from={from}
                to={to}
                setFrom={setFrom}
                setTo={setTo}
            />
        </div>
    );
}
