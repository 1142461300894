import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import commonStyles from "../../../style/common.module.css";
import { EXAMINE_SURVEY_RESPONDENT_VIEW, PARAMETER_SURVEY_RESPONDENT_ID } from "../../../../infrastructure/route";
import { useCache } from "../../../../context/cache/cache-provider";
import { columns } from "./survey-respondent-list-column-definition";
import { SurveyRespondent } from "../../model/survey-respondent";
import { getSurveyRespondents } from "../../service/survey-respondent-service";
import { BaseList } from "../../../../component/list/base-list";

export const SURVEY_RESPONDENT_LIST_FILTER_STATE: string = "SURVEY_RESPONDENT_LIST_FILTER_STATE";
export const SURVEY_RESPONDENT_LIST_SORTING_STATE: string = "SURVEY_RESPONDENT_LIST_SORTING_STATE";

export default function SurveyRespondentList() {
    const [loading, setLoading] = useState(true);
    const [surveyRespondents, setSurveyRespondents] = useState<SurveyRespondent[]>([]);

    const navigate = useNavigate();
    const cache = useCache();

    useEffect(() => {
        setLoading(true);
        getSurveyRespondents().then(result => {
            setSurveyRespondents(result ?? []);
            setLoading(false);
        });
    }, []);

    const openRead = (surveyRespondentId: string) => {
        const surveyRespondent = surveyRespondents.find(_ => _.id === Number(surveyRespondentId));
        cache.update({ key: PARAMETER_SURVEY_RESPONDENT_ID, value: surveyRespondent?.identity ?? '' });

        navigate(EXAMINE_SURVEY_RESPONDENT_VIEW.replace(PARAMETER_SURVEY_RESPONDENT_ID, `${surveyRespondentId}`));
    }

    return (
        <div className={commonStyles.content} >
            <BaseList
                storageKey="SURVEY_RESPONDENTS"
                rows={surveyRespondents}
                columns={columns}
                loading={loading}
                localeText={{ noRowsLabel: "No survey respondents" }}
                getRowId={(row) => `${row?.id}`}
                onRowClick={(e) => openRead(e.row?.id)}
            />
        </div>
    );
}
