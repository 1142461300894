import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { User } from "../model/user";
import ViewLoader from "../../../component/misc/view-loader";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useCache } from "../../../context/cache/cache-provider";
import { PARAMETER_APPLICANT_ID } from "../../../infrastructure/route";
import { getUserApplicant } from "../service/applicant-service";
import ApplicantHandler from "./applicant-handler/applicant-handler";

export const APPLICANT_TAB_STATE: string = "APPLICANT_TAB_STATE";
export const APPLICANT_TAB_STATE_ADD_EDIT: string = "APPLICANT_TAB_STATE_ADD_EDIT";

export default function ApplicantView() {
    const { applicantId } = useParams();
    const newModel = applicantId === undefined;

    const [tab, setTab] = useState<string>(localStorage.getItem(APPLICANT_TAB_STATE) ?? APPLICANT_TAB_STATE_ADD_EDIT);
    const [loading, setLoading] = useState(true);
    const [applicant, setApplicant] = useState<User>({
        id: Number(applicantId ?? "0"),
        name: "",
        email: "",
        phone: "",
        roles: [],
        active: true
    });

    const cache = useCache();

    useEffect(() => {
        cache.update({ key: PARAMETER_APPLICANT_ID, value: applicant.name });
    }, [cache, applicant]);

    useEffect(() => {
        if (!newModel) {
            setLoading(true);
            getUserApplicant(Number(applicantId)).then(result => {
                setApplicant(result);
                setLoading(false);
            })
        } else {
            setLoading(false);
        }
    }, [applicantId, newModel]);

    const changeTab = (newTab: string) => {
        localStorage.setItem(APPLICANT_TAB_STATE, newTab);
        setTab(newTab);
    }

    return (
        <React.Fragment>
            <ViewLoader loading={loading} />
            {loading === false && <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(_event: React.SyntheticEvent, newTab: string) => changeTab(newTab)}>
                        <Tab label={newModel === true ? "Add" : "Edit"} value={APPLICANT_TAB_STATE_ADD_EDIT} />
                    </TabList>
                </Box>
                <TabPanel value={APPLICANT_TAB_STATE_ADD_EDIT}>
                    <ApplicantHandler
                        newModel={newModel}
                        applicant={applicant}
                        setApplicant={setApplicant}
                    />
                </TabPanel>
            </TabContext>}
        </React.Fragment>
    );
}
