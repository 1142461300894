import React, { useEffect, useState } from "react";
import { User, sortUserList, userDisplayName } from "../../../-account/model/user";
import commonStyles from "../../../style/common.module.css";
import { useToast } from "../../../../context/toast/toast-provider";
import { Cohort, cohortDisplayName } from "../../model/cohort";
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import ViewLoader from "../../../../component/misc/view-loader";
import { getUserStudents } from "../../../-account/service/student-service";
import { Gender } from "../../../enum/gender";
import { Alert } from "@mui/material";
import { UserMultiSelect } from "../../../../component/mulit-select/user/user-multi-select";
import { UserMultiSelectFilterModel } from "../../../../component/mulit-select/user/filter/user-multi-select-filter-model";
import { studentRoles } from "../../../enum/role";
import { YesNo } from "../../../../component/mulit-select/enum/yes-no";
import { updateCohortStudents } from "../../service/cohort-service";

export default function CohortStudentAssign({ cohort }: { cohort: Cohort }) {
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [selectedStudents, setSelectedStudents] = useState<User[]>([]);
    const [studentFilter, setStudentFilter] = useState<UserMultiSelectFilterModel>({
        cohortId: cohort.id,
        hideCohortId: true,
        gender: cohort.gender,
        cohortAssigned: 2,
        userSearch: '',
        usersSelected: YesNo.All
    });

    const toast = useToast();

    useEffect(() => {
        setLoading(true);

        getUserStudents(Number(cohort.id)).then((result) => {
            setSelectedStudents(sortUserList((result ?? []).filter(_ => _.active === true)));
            setLoading(false);
        });
    }, [cohort.id]);

    const handleSubmit = () => {
        setSubmitting(true);

        updateCohortStudents(cohort.id!, selectedStudents.map(_ => _.id)).then(result => {
            if (result !== undefined) {
                toast.addToast(`Cohort ${cohortDisplayName(cohort)} updated to include ${result.length} student(s)`, "success");
            } else {
                toast.addToast(`Cohort ${cohortDisplayName(cohort)} updated and all student(s) were removed`, "success");
            }

            setSelectedStudents(sortUserList(result ?? []));
            setSubmitting(false);
        }).catch(() => {
            toast.addToast(`Unable to update cohort ${cohortDisplayName(cohort)} adding student(s)`, "error");
            setSubmitting(false);
        });
    }

    const selectedStudentRender = (user: User) => {
        const userGender = user.gender !== undefined ? Gender[user.gender] : 'unknown';
        const cohortGender = cohort.gender !== undefined ? Gender[cohort.gender] : Gender[Gender.All];

        const userName = `<b>${userDisplayName(user)}</b>`;
        const incorrectGender = userGender !== cohortGender && cohortGender !== Gender[Gender.All] ? `Cohort gender is <b>${cohortGender}</b> but student gender is <b>${userGender}</b>.` : undefined;
        const anotherCohort = user.cohortId !== undefined && user.cohortId !== cohort.id ? `Student is already assigned to another cohort.` : undefined;
        const userStatus = [userName, incorrectGender, anotherCohort].filter(_ => _ !== undefined).join('. ');
        const severity = (incorrectGender !== undefined || anotherCohort !== undefined) ? 'warning' : 'success';

        return <div key={user.id} className={commonStyles.fsb} >
            <Alert severity={severity} className={commonStyles.alert} sx={{ width: '100%' }} >
                <span dangerouslySetInnerHTML={{ __html: userStatus }}></span>
            </Alert>
            <PersonRemoveIcon
                className={commonStyles.iconClickable}
                sx={{ marginLeft: '0.5rem', marginTop: '0.8rem' }}
                onClick={() => {
                    const updateSelected = [...selectedStudents];
                    const userIndex = updateSelected.findIndex(_ => _ === user);
                    updateSelected.splice(userIndex, 1);
                    setSelectedStudents(updateSelected);
                }}
            />
        </div>
    }

    return (
        <React.Fragment>
            <ViewLoader loading={loading} />
            {loading === false && <UserMultiSelect
                selectedItems={selectedStudents}
                setSelectedItems={setSelectedStudents}
                filter={studentFilter}
                setFilter={setStudentFilter}
                userRoles={studentRoles}
                handleSubmit={handleSubmit}
                submitting={submitting}
                
                selectedItemsTitle={selectedStudents.length > 0 ? `Selected ${selectedStudents.length} student(s)` : 'Selected student(s)'}
                selectedItemsNoneText='No students selected'
                selectedItemRender={selectedStudentRender}

                availableItemsTitle='Available student(s)'
                availableItemsNoneText='No students available'
            />}
        </React.Fragment>
    );
}
