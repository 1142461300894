import { SurveyAnswerType } from "../enum/survey-answer-type";
import { SurveyErrorType } from "../enum/survey-error-type";
import { SurveyQuestionAnswer } from "./survey-question-answer";

export interface SurveyQuestion {
    id?: number | undefined,
    sequence: number,
    title: string,
    answerType: SurveyAnswerType,
    answerRequired: boolean,
    answers: SurveyQuestionAnswer[]
}

export interface QuestionValidationError {
    errorType: SurveyErrorType,
    sequence: number,
    error: string
}

export const isAnswerOptionType = (answerType: SurveyAnswerType) => {
    return answerType === SurveyAnswerType.Multi_Select ||
        answerType === SurveyAnswerType.Select ||
        answerType === SurveyAnswerType.Radio;
}