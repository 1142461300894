import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ViewLoader from "../../../component/misc/view-loader";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useCache } from "../../../context/cache/cache-provider";
import { PARAMETER_SURVEY_ID } from "../../../infrastructure/route";
import { Survey } from "../model/survey";
import { getSurvey } from "../service/survey-service";
import SurveyHandler from "./-survey-handler/survey-handler";

export const SURVEY_TAB_STATE: string = "SURVEY_TAB_STATE";
export const SURVEY_TAB_STATE_ADD_EDIT: string = "SURVEY_TAB_STATE_ADD_EDIT";

export default function SurveyView() {
    const { surveyId } = useParams();
    const newModel = surveyId === undefined;

    const [tab, setTab] = useState<string>(localStorage.getItem(SURVEY_TAB_STATE) ?? SURVEY_TAB_STATE_ADD_EDIT);
    const [loading, setLoading] = useState<boolean>(true);
    const [survey, setSurvey] = useState<Survey>({
        title: '',
        description: '',
        active: true,
        hasSubmits: false,
        questions: [],
        accessRoles: [],
        acceptRoles: []
    });

    const cache = useCache();

    useEffect(() => {
        cache.update({ key: PARAMETER_SURVEY_ID, value: survey.title });
    }, [cache, survey]);

    useEffect(() => {
        if (!newModel) {
            setLoading(true);
            getSurvey(surveyId).then(result => {
                setSurvey(result);
                setLoading(false);
            })
        } else {
            setLoading(false);
        }
    }, [surveyId, newModel]);

    const changeTab = (newTab: string) => {
        localStorage.setItem(SURVEY_TAB_STATE, newTab);
        setTab(newTab);
    }

    return (
        <React.Fragment>
            <ViewLoader loading={loading} />
            {loading === false && <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(_: React.SyntheticEvent, newTab: string) => changeTab(newTab)}>
                        <Tab label={newModel === true ? "Add" : "Edit"} value={SURVEY_TAB_STATE_ADD_EDIT} />
                    </TabList>
                </Box>
                <TabPanel value={SURVEY_TAB_STATE_ADD_EDIT}>
                    <SurveyHandler
                        newModel={newModel}
                        survey={survey}
                        setSurvey={setSurvey}
                    />
                </TabPanel>
            </TabContext>}
        </React.Fragment>
    );
}
