import { SelectItem } from "../../../../component/form/select-item";
import { BadgeAssignment } from "../../model/badge-assignment";
import { ModuleAssignment } from "../../model/module-assignment";
import { getSelectedModule } from "../../utils/assignment-auto-select";
import { ASSIGNMENT_QUERY_PARAMETER_BADGEID, ASSIGNMENT_QUERY_PARAMETER_MODULEID } from "../../utils/assignment-constants";

export interface AssignmentFilter {
    moduleId?: string | undefined,
    badgeId?: string | undefined
}

export const getUnsubmittedBadges = (unsubmittedModuleAssignment?: ModuleAssignment) => {
    return unsubmittedModuleAssignment?.badges.filter(_ => 
        _.submissionDate === undefined || 
        _.canResubmit === true) ?? [] as BadgeAssignment[]
}

export const getSelectedBadge = (
    unsubmittedModuleAssignment?: ModuleAssignment,
    searchParams?: URLSearchParams | undefined
) => {
    const badgeId = searchParams?.get(ASSIGNMENT_QUERY_PARAMETER_BADGEID);
    if (unsubmittedModuleAssignment !== undefined && badgeId !== null && badgeId !== undefined && badgeId.trim() !== '') {
        return unsubmittedModuleAssignment.badges.find(_ => _.badgeId === badgeId)
    } else if (unsubmittedModuleAssignment !== undefined && getUnsubmittedBadges(unsubmittedModuleAssignment).length === 1) {
        return getUnsubmittedBadges(unsubmittedModuleAssignment)[0];
    } else {
        return undefined
    }
}

export const getFilter = (
    unsubmittedModuleAssignments: ModuleAssignment[],
    searchParams: URLSearchParams
) => {
    const unsubmittedModuleAssignment = getSelectedModule(unsubmittedModuleAssignments, searchParams);
    const unsubmittedBadgeAssignment = getSelectedBadge(unsubmittedModuleAssignment, searchParams);

    searchParams.delete(ASSIGNMENT_QUERY_PARAMETER_BADGEID);
    searchParams.delete(ASSIGNMENT_QUERY_PARAMETER_MODULEID);

    return {
        moduleId: unsubmittedModuleAssignment?.moduleId,
        badgeId: unsubmittedBadgeAssignment?.badgeId
    } as AssignmentFilter;
}

export const getUnsubmittedBadgeSelectItems = (selectedModule: ModuleAssignment) => {
    return getUnsubmittedBadges(selectedModule).map(_ => {
        let selectItem: SelectItem = {
            id: _.badgeId,
            name: (_.canResubmit === true ? `${_.badgeName} (Resubmit)` : _.badgeName) ?? `Badge ${_.sequence}`
        }
        return selectItem;
    });
}