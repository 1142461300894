import { Role } from "../../enum/role";
import { HttpMethod, requestOptions } from "../../../utils/api/request-options";
import { handleResponse, handleResponseNoContent } from "../../../utils/api/response-handler";
import { User } from "../model/user";

export function getSelf(accessToken: string): Promise<User> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/actor/self`, requestOptions)
        .then(response => {
            return handleResponse<User>(response)
        });
}

export function getUsers(roles?: Role[]): Promise<User[]> {
    var rolesQuery = roles?.map(_ => `roles=${_}`).join('&');
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/actor/list?${rolesQuery ?? ''}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<User[]>(response)
        });
}

export function getUser(userId: number): Promise<User> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/actor/${userId}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<User>(response)
        });
}

export function addUser(user: User): Promise<User> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/actor`, requestOptions(HttpMethod.POST, user))
        .then(response => {
            return handleResponse<User>(response)
        });
}

export function updateUser(user: User): Promise<User> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/actor`, requestOptions(HttpMethod.PUT, user))
        .then(response => {
            return handleResponse<User>(response)
        });
}

export function deleteUser(userId: number): Promise<void> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/actor/${userId}`, requestOptions(HttpMethod.DELETE))
        .then(response => {
            return handleResponseNoContent(response)
        });
}