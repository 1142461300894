import { Gender } from "../../enum/gender"
import { CourseLevel } from "../enum/course-level"

export interface Cohort {
    id?: number | undefined,
    name: string,
    year: number,
    age?: number | undefined,
    courseLevelId?: CourseLevel | undefined,
    courseLevelName?: string | undefined,
    gender?: Gender | undefined,
    targetGroups?: string[] | undefined,
    active: boolean,
    hasAttendance: boolean,
    hasPresentations: boolean,
    students?: number | undefined,
}

export const cohortDisplayName = (cohort: Cohort) => {
    return `${cohort.courseLevelName ?? ''} - ${cohort.name} - ${cohort.year}`
}