export enum Role {
    Administrator,
    BetaTester,
    Evaluator,
    CourseEditor,
    SuperEvaluator,
    ATLead,
    LeadTrainer,
    Registrar,
    Student,
    Trainer,
    QualiMaster,
    BootcampTrainee,
    CohortCaptain,
    ExamineManager,
    Explorer,
    Applicant
}

export const roles = Object.values(Role).filter((v) => !isNaN(Number(v))) as number[]

export const studentRoles = [Role.Student, Role.BootcampTrainee, Role.Explorer] as number[]

export const isApplicant = (userRoles: Role[]) => {
    return userRoles.length === 1 && userRoles.find(_ => _ = Role.Applicant) !== undefined;
}