import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { columns } from "./applicant-list-column-definition";
import commonStyles from "../../../style/common.module.css";
import { User } from "../../model/user";
import { getUserApplicants } from "../../service/applicant-service";
import { ACCOUNT_MANAGEMENT_APPLICANT_ADD, ACCOUNT_MANAGEMENT_APPLICANT_EDIT, PARAMETER_APPLICANT_ID } from "../../../../infrastructure/route";
import { APPLICANT_TAB_STATE } from "../applicant-view";
import { useCache } from "../../../../context/cache/cache-provider";
import { BaseList } from "../../../../component/list/base-list";

export default function ApplicantList() {
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState<User[]>([]);

    const navigate = useNavigate();
    const cache = useCache();

    useEffect(() => {
        setLoading(true);
        getUserApplicants().then(result => {
            setUsers(result);
            setLoading(false);
        });
    }, []);

    const openAdd = () => {
        localStorage.removeItem(APPLICANT_TAB_STATE);
        navigate(ACCOUNT_MANAGEMENT_APPLICANT_ADD);
    }

    const openEdit = (applicantId: number) => {
        const applicant = users.find(_ => _.id === Number(applicantId));
        cache.update({ key: PARAMETER_APPLICANT_ID, value: applicant?.name ?? '' });

        localStorage.removeItem(APPLICANT_TAB_STATE);
        navigate(ACCOUNT_MANAGEMENT_APPLICANT_EDIT.replace(PARAMETER_APPLICANT_ID, applicantId.toString()))
    }

    return (
        <div className={commonStyles.content} >
            <BaseList
                storageKey="APPLICANT"
                rows={users}
                columns={columns}
                loading={loading}
                localeText={{ noRowsLabel: "No applicants" }}
                getRowId={(row) => row?.id}
                onRowClick={(e) => openEdit(e.row?.id)}
                onAddClick={() => openAdd()}
                addButtonText="Add applicant"
            />
        </div>
    );
}
