import { getAccessToken } from "./access-token-handler"

export enum HttpMethod {
    GET,
    POST,
    PUT,
    DELETE
}

export const requestOptions = (
    httpMethod: HttpMethod, 
    content?: unknown | undefined, 
    authorization?: boolean | undefined
) => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/json');
    if(authorization === undefined || authorization === true) {
        requestHeaders.set('Authorization', `Bearer ${getAccessToken()}`);
    }

    return {
        method: HttpMethod[httpMethod],
        headers: requestHeaders,
        body: content !== undefined ? JSON.stringify(content) : undefined
    }
}