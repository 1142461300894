import React, { useEffect, useState } from "react";
import SaveIcon from '@mui/icons-material/Save';
import { Module, sortModuleList } from "../../../section/-education/model/module";
import { getModules } from "../../../section/-education/service/module-service";
import BaseMutliSelect from "../base-multi-select";
import ViewLoader from "../../misc/view-loader";
import BaseMultiSelect from "../base-multi-select";
import { ModuleMultiSelectFilterModel, filterModules } from "./filter/module-multi-select-filter-model";
import ModuleMultiSelectFilter from "./filter/module-multi-select-filter";
import BaseOperations from "../../operations/base-operations";

export const ModuleMultiSelect = (
    props: Omit<React.ComponentProps<typeof BaseMultiSelect>, "classes"> & {
        filter: ModuleMultiSelectFilterModel,
        setFilter: React.Dispatch<React.SetStateAction<ModuleMultiSelectFilterModel>>,
        handleSubmit(): void,
        submitting: boolean,
        helpInfoTexts?: string[] | undefined
    }
) => {
    const [loading, setLoading] = useState(true);
    const [modules, setModules] = useState<Module[]>([]);

    useEffect(() => {
        if (modules.length === 0) {
            setLoading(true);
            getModules(undefined, true).then((result) => {
                setModules(sortModuleList(result));
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, [modules.length, props.filter]);

    const handleAvailableRender = (module: Module) => {
        if (props.availableItemRender !== undefined) {
            return props.availableItemRender(module);
        } else {
            return <span key={`${module.moduleId}-available-module`}>{module.moduleId} - {module.title}</span>
        }
    }

    const handleSelectedRender = (module: Module) => {
        if (props.availableItemRender !== undefined) {
            return props.availableItemRender(module);
        } else {
            return <p key={`${module.moduleId}-selected-module`}>{module.moduleId} - {module.title} ({module.totalBadges} badges, {module.totalEstimate} h)</p>
        }
    }

    return (
        <React.Fragment>
            <ViewLoader loading={loading} />
            {loading === false && <ModuleMultiSelectFilter
                filter={props.filter}
                setFilter={props.setFilter}
            />}
            {loading === false && <BaseMutliSelect
                {...props}
                items={filterModules(props.filter, modules, props.selectedItems)}
                itemIdKey={'moduleId'}

                selectedItemsTitle={props.selectedItems.length > 0 ? `Selected ${props.selectedItems.length} module(s)` : 'Selected module(s)'}
                selectedItemsNoneText='No modules selected'
                selectedItemRender={(e) => handleSelectedRender(e as Module)}

                availableItemsTitle='Available module(s)'
                availableItemsNoneText='No modules available'
                availableItemRender={(e) => handleAvailableRender(e as Module)}

                disabled={props.submitting}
            />}
            {loading === false && <BaseOperations
                primaryButtonText="Save"
                primaryButtonIcon={<SaveIcon />}
                onPrimaryButtonClick={() => props.handleSubmit()}
                primaryButtonLoading={props.submitting}
                helpInfoTexts={props.helpInfoTexts}
            />}
        </React.Fragment>
    );
}
