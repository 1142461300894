import React, { useEffect, useState } from "react";
import { useToast } from "../../../../context/toast/toast-provider";
import { Cohort, cohortDisplayName } from "../../model/cohort";
import { Module } from "../../model/module";
import { getCohortModules, updateCohortModules } from "../../service/cohort-service";
import ViewLoader from "../../../../component/misc/view-loader";
import { ModuleMultiSelect } from "../../../../component/mulit-select/module/module-multi-select";
import { ModuleMultiSelectFilterModel } from "../../../../component/mulit-select/module/filter/module-multi-select-filter-model";
import { YesNo } from "../../../../component/mulit-select/enum/yes-no";

export default function CohortModuleAssign({ cohort }: { cohort: Cohort }) {
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [selectedModules, setSelectedModules] = useState<Module[]>([]);
    const [moduleFilter, setModuleFilter] = useState<ModuleMultiSelectFilterModel>({
        courseLevel: cohort.courseLevelId,
        lockCourseLevel: true,
        moduleCategory: -1,
        moduleSearch: '',
        modulesSelected: YesNo.All
    });

    const toast = useToast();

    useEffect(() => {
        setLoading(true);

        getCohortModules(cohort.id!).then((result) => {
            setSelectedModules(result ?? []);
            setLoading(false);
        });
    }, [cohort.id]);

    const handleSubmit = () => {
        setSubmitting(true);

        updateCohortModules(cohort.id!, selectedModules.map(_ => _.moduleId)).then(result => {
            if (result !== undefined) {
                toast.addToast(`Cohort ${cohortDisplayName(cohort)} updated to cover ${result.length} module(s)`, "success");
            } else {
                toast.addToast(`Cohort ${cohortDisplayName(cohort)} updated and all module(s) were removed`, "success");
            }

            setSubmitting(false);
        }).catch(() => {
            toast.addToast(`Unable to update module(s) for cohort ${cohortDisplayName(cohort)}`, "error");
            setSubmitting(false);
        });
    }

    return (
        <React.Fragment>
            <ViewLoader loading={loading} />
            {loading === false && <ModuleMultiSelect
                selectedItems={selectedModules}
                setSelectedItems={setSelectedModules}
                filter={moduleFilter}
                setFilter={setModuleFilter}
                handleSubmit={handleSubmit}
                submitting={submitting}
                helpInfoTexts={[
                    `You can only choose modules which belong course levels <b>${cohort.courseLevelName}</b>, the same as cohort <b>${cohortDisplayName(cohort)}</b>, which you are working on.`
                ]}
            />}
        </React.Fragment>
    );
}
