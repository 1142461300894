import { useEffect, useState } from "react";
import { Dayjs } from "dayjs";
import { BaseList } from "../../../../component/list/base-list";
import { columns } from "./assignment-list-column-definition";
import { getWorkItemAssignments } from "../../service/assignment-service";
import { WorkItemAssignment } from "../../model/work-item-assignment";
import { useNavigate } from "react-router-dom";
import { useCache } from "../../../../context/cache/cache-provider";
import { ASSIGNMENT_MANAGEMENT, PARAMETER_ENCODED_ORIGIN_URL, PARAMETER_WORK_ITEM_ID } from "../../../../infrastructure/route";
import { encodeUrl } from "../../../../utils/helper/string-helper";

const MAX_DAY_SPAN = 30;

export default function AssignmentList({
    cohortId,
    moduleId,
    badgeId,
    studentId,
    evaluatorId,
    originUrl
}: {
        cohortId?: number | undefined,
        moduleId?: string | undefined,
        badgeId?: string | undefined,
        studentId?: number | undefined,
        evaluatorId?: number | undefined
        originUrl: string
}) {
    const [loading, setLoading] = useState<boolean>(false);
    const [from, setFrom] = useState<Dayjs | null>(null);
    const [to, setTo] = useState<Dayjs | null>(null);
    const [workItemAssignments, setWorkItemAssignments] = useState<WorkItemAssignment[]>([]);

    const cache = useCache();
    const navigate = useNavigate();
    
    useEffect(() => {
        if (from !== null && to !== null && 
            to.diff(from, 'day') <= MAX_DAY_SPAN && 
            to.diff(from, 'day') >= 0
        ) {
            setLoading(true);
            getWorkItemAssignments(from.format('YYYY-MM-DD'), 
                to.format('YYYY-MM-DD'), 
                cohortId,
                moduleId,
                badgeId,
                studentId,
                evaluatorId).then((result) => {
                    setWorkItemAssignments(result);
                    setLoading(false);
                });
        }
    }, [from, 
        to, 
        cohortId,
        moduleId,
        badgeId,
        studentId,
        evaluatorId
    ]);

    const getColumns = () => {
        var columnsAddapted = [...columns];
        if (cohortId !== undefined) {
            var cohortColumn = columnsAddapted.find(_ => _.field === 'cohortName');
            var cohortColumnIndex = columnsAddapted.indexOf(cohortColumn!, 0)
            columnsAddapted.splice(cohortColumnIndex, 1);
        }
        if (moduleId !== undefined) {
            var moduleColumn = columnsAddapted.find(_ => _.field === 'moduleName');
            var moduleColumnIndex = columnsAddapted.indexOf(moduleColumn!, 0)
            columnsAddapted.splice(moduleColumnIndex, 1);
        }
        if (badgeId !== undefined) {
            var badgeColumn = columnsAddapted.find(_ => _.field === 'badgeName');
            var badgeColumnIndex = columnsAddapted.indexOf(badgeColumn!, 0)
            columnsAddapted.splice(badgeColumnIndex, 1);
        }
        if (studentId !== undefined) {
            var studentColumn = columnsAddapted.find(_ => _.field === 'studentName');
            var studentColumnIndex = columnsAddapted.indexOf(studentColumn!, 0)
            columnsAddapted.splice(studentColumnIndex, 1);
        }
        if (evaluatorId !== undefined) {
            var evaluatorColumn = columnsAddapted.find(_ => _.field === 'evaluatorName');
            var evaluatorColumnIndex = columnsAddapted.indexOf(evaluatorColumn!, 0)
            columnsAddapted.splice(evaluatorColumnIndex, 1);
        }

        return columnsAddapted;
    }

    const openEdit = (workItemId: number) => {
        const workItem = workItemAssignments.find(_ => _.workItemId === workItemId);
        cache.update({ key: PARAMETER_WORK_ITEM_ID, value: workItem?.badgeName ?? '' });
        cache.update({ key: PARAMETER_ENCODED_ORIGIN_URL, value: '' });

        navigate(ASSIGNMENT_MANAGEMENT
            .replace(PARAMETER_WORK_ITEM_ID, `${workItemId}`)
            .replace(PARAMETER_ENCODED_ORIGIN_URL, `${encodeUrl(originUrl)}`));
    }

    return (
        <BaseList
            storageKey="ASSIGNMENT_OVERVIEW"
            rows={workItemAssignments}
            columns={getColumns()}
            loading={loading}
            localeText={{ noRowsLabel: "No assignments" }}
            getRowId={(row) => row?.workItemId}
            onRowClick={(e) => openEdit(e.row?.workItemId)}
            from={from}
            to={to}
            setFrom={setFrom}
            setTo={setTo}
            maxDaySpan={MAX_DAY_SPAN}
        />
    );
}