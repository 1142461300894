import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Presentation } from "../model/presentation";
import { getPresentation } from "../service/presentation-service";
import ViewLoader from "../../../component/misc/view-loader";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import PresentationHandler from "./-presentation-handler/presentation-handler";
import { useCache } from "../../../context/cache/cache-provider";
import { PARAMETER_PRESENTER_DAY, PARAMETER_PRESENTER_ID } from "../../../infrastructure/route";
import { getCohorts } from "../service/cohort-service";
import { Cohort } from "../model/cohort";

const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

export const PRESENTATION_TAB_STATE: string = "PRESENTATION_TAB_STATE";
export const PRESENTATION_TAB_STATE_ADD_EDIT: string = "PRESENTATION_TAB_STATE_ADD_EDIT";

export default function PresentationView() {
    const { presenterId, day } = useParams();
    const newModel = presenterId === undefined || day === undefined;

    const [tab, setTab] = useState<string>(localStorage.getItem(PRESENTATION_TAB_STATE) ?? PRESENTATION_TAB_STATE_ADD_EDIT);
    const [loading, setLoading] = useState<boolean>(true);
    const [cohorts, setCohorts] = useState<Cohort[]>([]);
    const [presentation, setPresentation] = useState<Presentation>({
        cohortId: 0,
        presenterId: presenterId !== undefined ? Number(presenterId) : undefined,
        day: dayjs(day) ?? dayjs(new Date())
    });

    const cache = useCache();

    useEffect(() => {
        cache.update(
            { key: PARAMETER_PRESENTER_DAY, value: dayjs(presentation.day).format('YYYY-MM-DD') },
            { key: PARAMETER_PRESENTER_ID, value: presentation.presenterName ?? '' }
        );
    }, [cache, presentation]);

    useEffect(() => {
        if (!newModel) {
            setLoading(true);

            const getCohortsCall = getCohorts();
            const getPresentationCall = getPresentation(Number(presenterId), day!);

            Promise.all([getCohortsCall, getPresentationCall]).then(result => {
                setCohorts(result[0]);
                setPresentation(result[1]);
                setLoading(false);
            });
        } else {
            getCohorts().then(result => {
                setCohorts(result);
                setLoading(false);
            });
        }
    }, [day, presenterId, newModel]);

    const changeTab = (newTab: string) => {
        localStorage.setItem(PRESENTATION_TAB_STATE, newTab);
        setTab(newTab);
    }

    return (
        <React.Fragment>
            <ViewLoader loading={loading} />
            {loading === false && <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(event: React.SyntheticEvent, newTab: string) => changeTab(newTab)}>
                        <Tab label={newModel === true ? "Add" : "Edit"} value={PRESENTATION_TAB_STATE_ADD_EDIT} />
                    </TabList>
                </Box>
                <TabPanel value={PRESENTATION_TAB_STATE_ADD_EDIT}>
                    <PresentationHandler
                        newModel={newModel}
                        presentation={presentation}
                        setPresentation={setPresentation}
                        cohorts={cohorts}
                    />
                </TabPanel>
            </TabContext>}
        </React.Fragment>
    );
}
