import StarIcon from '@mui/icons-material/Star';
import { FieldDefinition } from '../../../../component/form/field-definition';

export const presentationFieldDefinitions: FieldDefinition[] = [
    {
        id: "cohortId",
        name: "Cohort",
        type: "select",
        required: false,
        disabled: false,
        values: []
    },
    {
        id: "presenterId",
        name: "Presenter",
        type: "search-select",
        required: true,
        disabled: true,
        values: []
    },
    {
        id: "day",
        name: "Day",
        type: "date",
        required: true,
        disabled: false
    },
    {
        id: "rating",
        name: "Rating",
        type: "number-select",
        required: true,
        disabled: false,
        min: 1,
        max: 3,
        icon: <StarIcon color="primary" />,
        values: []
    }
];