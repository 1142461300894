import { GridColDef } from "@mui/x-data-grid";
import { renderDate } from "../../../../utils/table-column-renders";
import { SurveySubmitStatus } from "../../enum/survey-submit-status";
import styles from "../../style/survey.module.css"

export const columns: GridColDef[] = [
    { field: 'submitDate', headerName: 'Submitted', flex: 1, valueFormatter: (params) => { return renderDate(params.value, true) } },
    { field: 'surveyTitle', headerName: 'Survey', flex: 1.5 },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'emailAddress', headerName: 'E-mail address', flex: 1 },
    { field: 'phoneNumber', headerName: 'Phone number', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 0.5, renderCell: (params) => { return renderStatus(params.row.status) }}
];

const renderStatus = (status: SurveySubmitStatus) => {
    if (status === SurveySubmitStatus.Accepted) {
        return <p className={styles.surveySubmitStatusAccepted}>Accepted</p>
    } else if (status === SurveySubmitStatus.Public) {
        return <p className={styles.surveySubmitStatusPublic}>Public</p>
    } else {
        return <p className={styles.surveySubmitStatusPending}>Pending</p>
    }
}