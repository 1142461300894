import { SelectItem } from "../../../component/form/select-item";
import { ModuleAssignment } from "../model/module-assignment";
import { ASSIGNMENT_QUERY_PARAMETER_MODULEID } from "./assignment-constants";

export const getSelectedModule = (
    moduleAssignments: ModuleAssignment[], 
    searchParams?: URLSearchParams | undefined
) => {
    const moduleId = searchParams?.get(ASSIGNMENT_QUERY_PARAMETER_MODULEID);
    if (moduleId !== null && moduleId !== undefined && moduleId.trim() !== '') {
        return moduleAssignments.find(_ => _.moduleId === moduleId);
    } else if (moduleAssignments.length === 1) {
        return moduleAssignments[0];
    } else {
        return undefined;
    }
}

export const getModuleSelectItems = (moduleAssignments: ModuleAssignment[]) => {
    return moduleAssignments.map(_ => {
        let selectItem: SelectItem = {
            id: _.moduleId,
            name: _.moduleName
        }
        return selectItem;
    });
}