import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { columns } from "./module-list-column-definition";
import commonStyles from "../../../style/common.module.css";
import { Module } from "../../model/module";
import { getModules } from "../../service/module-service";
import { EDUCATION_MANAGEMENT_MODULE_ADD, EDUCATION_MANAGEMENT_MODULE_EDIT, PARAMETER_MODULE_ID } from "../../../../infrastructure/route";
import { MODULE_TAB_STATE } from "../module-view";
import { useCache } from "../../../../context/cache/cache-provider";
import { BaseList } from "../../../../component/list/base-list";

export default function ModuleList() {
    const [loading, setLoading] = useState(true);
    const [modules, setModules] = useState<Module[]>([]);

    const navigate = useNavigate();
    const cache = useCache();

    useEffect(() => {
        setLoading(true);
        getModules().then(result => {
            setModules(result ?? []);
            setLoading(false);
        });
    }, []);

    const openEdit = (moduleId: string) => {
        const module = modules.find(_ => _.moduleId === moduleId);
        cache.update({ key: PARAMETER_MODULE_ID, value: module?.title ?? '' });

        localStorage.removeItem(MODULE_TAB_STATE);
        navigate(EDUCATION_MANAGEMENT_MODULE_EDIT.replace(PARAMETER_MODULE_ID, `${moduleId}`));
    }

    const openAdd = () => {
        localStorage.removeItem(MODULE_TAB_STATE);
        navigate(EDUCATION_MANAGEMENT_MODULE_ADD);
    }

    return (
        <div className={commonStyles.content} >
            <BaseList
                storageKey="MODULE"
                rows={modules}
                columns={columns}
                loading={loading}
                localeText={{ noRowsLabel: "No modules" }}
                getRowId={(row) => row?.moduleId}
                onRowClick={(e) => openEdit(e.row?.moduleId)}
                onAddClick={() => openAdd()}
                addButtonText="Add module"
            />
        </div>
    );
}
