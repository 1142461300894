import { CourseLevel } from "../enum/course-level";
import { ModuleCategory } from "../enum/module-category";

export interface Module {
    moduleId: string,
    title: string,
    categoryId?: ModuleCategory | undefined,
    categoryName?: string | undefined,
    courseLevelId?: CourseLevel | undefined,
    courseLevelName?: string | undefined,
    totalBadges?: number | undefined,
    totalEstimate?: number | undefined,
    active: boolean
}

export const sortModuleList = (modules: Module[]) => {
    return modules
        .sort((module1, module2) => (module1?.title ?? '')
            .localeCompare((module2?.title ?? '')))
}

export const moduleDisplayName = (module: Module) => {
    return `${module.moduleId} - ${module.title}`
}