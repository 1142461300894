import { groupGenderOptions } from "../../../../utils/form-select-input-options";
import { FieldDefinition } from "../../../form/field-definition";
import { yesNoOptions } from "../../enum/yes-no";

export const userMultiSelectFilterFieldDefinitions: FieldDefinition[] = [
    {
        id: "usersSelected",
        name: "Selected users",
        type: "select",
        required: false,
        disabled: false,
        values: yesNoOptions()
    },
    {
        id: "cohortId",
        name: "Cohort",
        type: "text",
        required: false,
        disabled: false
    },
    {
        id: "gender",
        name: "Gender",
        type: "select",
        required: false,
        disabled: false,
        values: groupGenderOptions()
    },
    {
        id: "cohortAssigned",
        name: "Cohort assigned",
        type: "select",
        required: false,
        disabled: false,
        values: yesNoOptions()
    },
    {
        id: "userSearch",
        name: "User search",
        type: "text",
        required: false,
        disabled: false
    }
];