import { GridColDef } from "@mui/x-data-grid";
import { renderChecked } from "../../../../../utils/table-column-renders";

export const columns: GridColDef[] = [
    { field: 'cohortName', headerName: 'Cohort', flex: 1 },
    { field: 'moduleTitle', headerName: 'Module', flex: 1 },
    { field: 'badgeTitle', headerName: 'Badge', flex: 1 },
    { field: 'studentName', headerName: 'Student', flex: 1 },
    { field: 'studentEmailAddress', headerName: 'Email', flex: 1 },
    { field: 'canResubmit', headerName: 'Can resubmit', flex: 0.5, renderCell: (params) => { return renderChecked(params.row.allowResubmit) } },
    { field: 'resubmits', headerName: 'Resubmits', flex: 0.5 },
    { field: 'rating', headerName: 'Rating', flex: 0.5 }
];