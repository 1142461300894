import { SurveyPublic } from "../../-examine/model/survey-public";
import { SurveySubmit } from "../../-examine/model/survey-submit";
import { requestOptions, HttpMethod } from "../../../utils/api/request-options";
import { handleResponse, handleResponseNoContent } from "../../../utils/api/response-handler";;

export function getSurvey(surveyId: string, email?: string | undefined): Promise<SurveyPublic> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/public/survey/${surveyId}?email=${email ?? ''}`, requestOptions(HttpMethod.GET, undefined, false))
        .then(response => {
                return handleResponse<SurveyPublic>(response)
        });
}

export function surveyRequireLogin(surveyId: string): Promise<boolean> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/public/survey/${surveyId}/require-login`, requestOptions(HttpMethod.GET, undefined, false))
        .then(response => {
            return handleResponse<boolean>(response)
        });
}

export function surveySubmit(surveySubmit: SurveySubmit): Promise<void> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/public/survey/submit`, requestOptions(HttpMethod.POST, surveySubmit, false))
        .then(response => {
            return handleResponseNoContent(response)
        });
}