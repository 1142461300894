import { FieldDefinition } from "../../../component/form/field-definition";

export const surveyRespondentFieldDefinitions: FieldDefinition[] = [
    {
        id: "name",
        name: "What is your name?",
        type: "text",
        required: true,
        disabled: false,
    },
    {
        id: "phoneNumber",
        name: "What is your phone number?",
        type: "phone",
        required: true,
        disabled: false
    },
    {
        id: "emailAddress",
        name: "What is your E-mail address?",
        type: "email",
        required: true,
        disabled: false
    }
];