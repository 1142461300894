import { FieldDefinition } from "../../../../component/form/field-definition";
import StarIcon from '@mui/icons-material/Star';

export const evaluatorUpdateFieldDefinitions: FieldDefinition[] = [
    {
        id: "rating",
        name: "Rating",
        type: "number-select",
        required: false,
        disabled: false,
        min: 0,
        max: 3,
        icon: <StarIcon color="primary" />
    },
    {
        id: "comment",
        name: "Comment",
        type: "text",
        required: false,
        disabled: false
    }
];