import { FieldDefinition } from "../../../component/form/field-definition";

export const surveySubmitAcceptFieldDefinitions: FieldDefinition[] = [
    {
        id: "acceptedRoles",
        name: "Roles accepted",
        type: "multi-select",
        required: false,
        disabled: false,
    }
];