import { FieldDefinition } from "../../../../component/form/field-definition";
import { individualGenderOptions, studentRoleOptions } from "../../../../utils/form-select-input-options";

export const studentFieldDefinitions: FieldDefinition[] = [
    {
        id: "name",
        name: "Name",
        type: "text",
        required: true,
        disabled: false
    },
    {
        id: "studentRole",
        name: "Student type",
        type: "select",
        required: true,
        disabled: false,
        values: studentRoleOptions()
    },
    {
        id: "phone",
        name: "Phone",
        type: "phone",
        required: false,
        disabled: false
    },
    {
        id: "email",
        name: "Email",
        type: "email",
        required: true,
        disabled: false
    },
    {
        id: "gender",
        name: "Gender",
        type: "select",
        required: false,
        disabled: false,
        values: individualGenderOptions()
    },
    {
        id: "cohortId",
        name: "Cohort",
        type: "select",
        required: false,
        disabled: true,
    },
    {
        id: "active",
        name: "Active",
        type: "boolean",
        required: true,
        disabled: false
    }
];