import { FieldDefinition } from "../../../component/form/field-definition";

export const surveyLoginFieldDefinitions: FieldDefinition[] = [
    {
        id: "email",
        name: "Your E-mail address?",
        type: "email",
        required: true,
        disabled: false
    }
];