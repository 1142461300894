import React, { useEffect, useState } from "react";
import DirectionsIcon from '@mui/icons-material/Directions';
import { useToast } from "../../../../context/toast/toast-provider";
import BaseForm from "../../../../component/form/base-form";
import { BadgeAssignment } from "../../model/badge-assignment";
import { ModuleAssignment } from "../../model/module-assignment";
import { FieldDefinition } from "../../../../component/form/field-definition";
import { reassignEvaluatorAssignment, updateEvaluatorAssignment } from "../../service/assignment-service";
import { evaluatorReviewSelectFieldDefinitions } from "./evaluator-review-select-field-definition";
import { evaluatorReviewFieldDefinitions } from "./evaluator-review-field-definition";
import { isValidHttpUrl } from "../../../../utils/helper/string-helper";
import { useSearchParams } from "react-router-dom";
import { ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_EVALUATOR_REVIEW } from "../../utils/assignment-constants";
import { getBadgeTerminology, getGeneralCourseLevel, getModuleCourseLevel, getModuleTerminology, getStudentTerminology } from "../../utils/terminology";
import { CourseLevel } from "../../../-education/enum/course-level";
import { updateSearchParams } from "../../utils/assignment-navigation";
import { informationText } from "../../../../context/dialog/dialog-context-type";
import { ApiError } from "../../../../utils/api/api-error";
import { ApiErrorType } from "../../../../utils/api/api-error-type";
import { AssignmentFilter, getFilter, getSelectedWorkItem, getUnreviewedWorkItemSelectItems } from "../utils/evaluator-assignment-tools";
import { getModuleSelectItems } from "../../utils/assignment-auto-select";

export default function EvaluatorReview({
    generalCourseLevel,
    unreviewedModuleAssignments,
    updateModuleAssignments,
    removeBadgeAssignment
}: {
        generalCourseLevel: CourseLevel,
        unreviewedModuleAssignments: ModuleAssignment[],
        updateModuleAssignments(badgeAssignment: BadgeAssignment): void
        removeBadgeAssignment(workItemId: number): void
}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [filter, setFilter] = useState<AssignmentFilter | undefined>();
    const [filterInitiated, setFilterInitiated] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [reassigning, setReassigning] = useState(false);
    const [reviewBadgeAssignment, setReviewBadgeAssignment] = useState<BadgeAssignment>({ comment: "" });

    const toast = useToast();
    const evaluatorCourseLevel = unreviewedModuleAssignments.length > 0 ? getGeneralCourseLevel(unreviewedModuleAssignments) : generalCourseLevel;

    useEffect(() => {
        if (filterInitiated === false) {
            setFilter(getFilter(unreviewedModuleAssignments, searchParams));
            setFilterInitiated(true);
        }
    }, [filterInitiated, searchParams, unreviewedModuleAssignments]);

    useEffect(() => {
        const selectedWorkItem = unreviewedModuleAssignments.flatMap(_ => _.badges).find(_ => _.workItemId === filter?.workItemId);
        if (selectedWorkItem !== undefined && selectedWorkItem.moduleId === filter?.moduleId) {
            setReviewBadgeAssignment({ ...selectedWorkItem, comment: ""});
        } else {
            setReviewBadgeAssignment({ comment: "" });
        }
    }, [unreviewedModuleAssignments, filter]);

    useEffect(() => {
        updateSearchParams(searchParams, setSearchParams, reviewBadgeAssignment, ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_EVALUATOR_REVIEW);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reviewBadgeAssignment]);

    useEffect(() => {
        const selectedModule = unreviewedModuleAssignments.find(_ => _.moduleId === filter?.moduleId);
        if (selectedModule !== undefined && selectedModule.moduleId !== filter?.moduleId) {
            let selectedWorkItem = getSelectedWorkItem(selectedModule);
            setFilter(filter => ({ ...filter, workItemId: selectedWorkItem?.workItemId }));
        }
    }, [unreviewedModuleAssignments, filter?.moduleId]);

    const getReviewSelectFieldDefinitions = () => {
        // Module
        const modules = getModuleSelectItems(unreviewedModuleAssignments)
        const selectedModule = unreviewedModuleAssignments?.find(_ => _.moduleId === filter?.moduleId);

        if (filter?.moduleId !== undefined && selectedModule === undefined) {
            setFilter({ ...filter, moduleId: undefined });
        }

        evaluatorReviewSelectFieldDefinitions.find(_ => _.id === "moduleId")!.name = getModuleTerminology(selectedModule?.moduleCourseLevelId ?? evaluatorCourseLevel);
        evaluatorReviewSelectFieldDefinitions.find(_ => _.id === "moduleId")!.values = modules;
        evaluatorReviewSelectFieldDefinitions.find(_ => _.id === "workItemId")!.disabled = true;

        // WorkItem
        if (selectedModule !== undefined) {
            const workItems = getUnreviewedWorkItemSelectItems(selectedModule);

            if (filter?.workItemId !== undefined && workItems?.find(_ => _.id === filter.workItemId) === undefined) {
                setFilter({ ...filter, workItemId: undefined });
            }

            evaluatorReviewSelectFieldDefinitions.find(_ => _.id === "workItemId")!.name = getBadgeTerminology(selectedModule?.moduleCourseLevelId ?? evaluatorCourseLevel);
            evaluatorReviewSelectFieldDefinitions.find(_ => _.id === "workItemId")!.values = workItems;
            evaluatorReviewSelectFieldDefinitions.find(_ => _.id === "workItemId")!.disabled = false;
        }

        return evaluatorReviewSelectFieldDefinitions;
    }

    const getReviewFieldDefinitions = (): FieldDefinition[] => {
        const selectedModule = unreviewedModuleAssignments.find(_ => _.moduleId === filter?.moduleId);
        reviewBadgeAssignment.resubmitInfo = reviewBadgeAssignment.allowResubmit === true ? `This submitted
            ${getBadgeTerminology(selectedModule?.moduleCourseLevelId ?? evaluatorCourseLevel)} can be resubmitted by the
            ${getStudentTerminology(evaluatorCourseLevel)} if you rate it with 'None' or '1 star'` : undefined

        const badgeTerminology = getBadgeTerminology(getModuleCourseLevel(unreviewedModuleAssignments, reviewBadgeAssignment.badgeId))

        evaluatorReviewFieldDefinitions.find(_ => _.id === "badgeInstruction")!.name = `${badgeTerminology} instructions`;
        evaluatorReviewFieldDefinitions.find(_ => _.id === "badgeAcceptanceCriteria")!.name = `${badgeTerminology} acceptance criteria`;
        evaluatorReviewFieldDefinitions.find(_ => _.id === "workingBadgePath")!.name = `${badgeTerminology} path`;
        evaluatorReviewFieldDefinitions.find(_ => _.id === "workingBadgePath")!.type = isValidHttpUrl(reviewBadgeAssignment.workingBadgePath) ? 'link' : 'info';
        evaluatorReviewFieldDefinitions.find(_ => _.id === "resubmitInfo")!.name = `The ${getStudentTerminology(evaluatorCourseLevel)} can resubmit`;
        evaluatorReviewFieldDefinitions.find(_ => _.id === "resubmitInfo")!.hidden = reviewBadgeAssignment.allowResubmit === true ? false: true;
        return evaluatorReviewFieldDefinitions;
    }

    const submitValidation = (badgeAssignment: BadgeAssignment, errors: any) => {
        const commentGiven = (badgeAssignment.comment !== undefined && badgeAssignment.comment !== '');
        const ratingGiven = (badgeAssignment.rating !== undefined && badgeAssignment.rating !== '' && Number(badgeAssignment.rating) !== 0);

        if (!commentGiven && !ratingGiven) {
            return {
                ...errors,
                rating: `Please provide either a comment or set a review rating`,
                comment: `Please provide either a comment or set a review rating`
            };
        }
        return errors
    }

    const handleSubmit = (badgeAssignment: BadgeAssignment) => {
        setSubmitting(true);

        badgeAssignment.rating = badgeAssignment.rating?.toString();
        const moduleCourseLevel = getModuleCourseLevel(unreviewedModuleAssignments, badgeAssignment.badgeId);

        updateEvaluatorAssignment(badgeAssignment).then(result => {
            toast.addToast(`Great work! ${getBadgeTerminology(moduleCourseLevel)} ${badgeAssignment.badgeName} submission has been reviewed`, "success");

            result.moduleId = badgeAssignment.moduleId;
            updateModuleAssignments(result);

            setReviewBadgeAssignment({ comment: "" });
            setFilter({ ...filter, workItemId: undefined });
            setSubmitting(false);
        }).catch(() => {
            toast.addToast(`Unable to review ${getBadgeTerminology(moduleCourseLevel)} ${badgeAssignment.badgeName} submission`, "error");
            setSubmitting(false);
        });
    }
    const handleReassign = () => {
        setReassigning(true);

        const moduleCourseLevel = getModuleCourseLevel(unreviewedModuleAssignments, reviewBadgeAssignment.badgeId);

        reassignEvaluatorAssignment(reviewBadgeAssignment.workItemId!).then(() => {
            toast.addToast(`${getBadgeTerminology(moduleCourseLevel)} ${reviewBadgeAssignment.badgeName} submission has been reassigned`, "success");
            
            removeBadgeAssignment(reviewBadgeAssignment.workItemId!);
            setReviewBadgeAssignment({ comment: "" });

            setFilter({ ...filter, workItemId: undefined });
            setReassigning(false);
        }).catch((error) => {
            if (((error as ApiError).apiErrorResponse).errorType === ApiErrorType.Assignment_CanNotReassign as number) {
                toast.addToast(`Unable to reassign ${getBadgeTerminology(moduleCourseLevel)} submission, no other available evaluator`, "error");
            } else {
                toast.addToast(`Unable to reassign ${getBadgeTerminology(moduleCourseLevel)} submission`, "error");
            }
            setReassigning(false);
        });
    }

    return (
        <React.Fragment>
            {unreviewedModuleAssignments.length > 0 && filter !== undefined && <BaseForm
                model={filter}
                setModel={setFilter}
                fields={getReviewSelectFieldDefinitions()}
            />}
            {reviewBadgeAssignment.workItemId !== undefined && <BaseForm
                model={reviewBadgeAssignment}
                setModel={setReviewBadgeAssignment}
                fields={getReviewFieldDefinitions()}
                submitValidation={submitValidation}
                submitEntity={handleSubmit}
                submitting={submitting}
                submitButtonText='Submit'
                
                customButtonText='Reassign'
                customButtonIcon={<DirectionsIcon />}
                customActionLoading={reassigning}
                customButtonConfirmationText={[informationText(`Are you sure you want to reassign 
                    ${getBadgeTerminology(getModuleCourseLevel(unreviewedModuleAssignments.filter(_ => _.moduleId === reviewBadgeAssignment.moduleId) ?? [], reviewBadgeAssignment.badgeId))}<b>
                    ${reviewBadgeAssignment.badgeName}</b>?`)]}
                customAction={handleReassign} 
            />}
            {unreviewedModuleAssignments.length === 0 && <div>
                <span>
                    You have no&nbsp;
                    {getBadgeTerminology(evaluatorCourseLevel)} to <b>Review</b>. You can look at your previous&nbsp;
                    {getBadgeTerminology(evaluatorCourseLevel)} reviews under your <b>Journal</b>.
                </span>
            </div>}
        </React.Fragment>
    );
}