import { AssignmentStatus } from "../enum/assignment-status";

export interface BadgeAssignment {
    workItemId?: number | undefined,
    moduleId?: string | undefined,
    badgeId?: string | undefined,
    badgeName?: string | undefined,
    badgeInstruction?: string | undefined,
    acceptanceCriteria?: string | undefined,
    sequence?: number | undefined,
    submissionDate?: Date | undefined,
    evaluatedDate?: Date | undefined,
    rating?: string | undefined,
    comment?: string | undefined,
    workingBadgePath?: string | undefined,
    assignmentStatus?: AssignmentStatus | undefined,
    allowResubmit?: boolean | undefined,
    canResubmit?: boolean | undefined,
    resubmits?: number | undefined,
    resubmitInfo?: string | undefined
}

export const isStudentEditable = (badgeAssignment: BadgeAssignment) =>
    badgeAssignment.assignmentStatus !== AssignmentStatus.Evaluated_Rated &&
    badgeAssignment.assignmentStatus !== AssignmentStatus.Closed

export const isEvaluatorEditable = (badgeAssignment: BadgeAssignment) =>
    badgeAssignment.assignmentStatus !== AssignmentStatus.Closed