import { requestOptions, HttpMethod } from "../../../utils/api/request-options";
import { handleResponse } from "../../../utils/api/response-handler";
import { SurveyRespondent } from "../model/survey-respondent";
import { SurveySubmit } from "../model/survey-submit";

export function getSurveyRespondents(): Promise<SurveyRespondent[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/survey/respondent/list`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<SurveyRespondent[]>(response)
        });
}

export function getSurveyRespondent(surveyRespondentId: number): Promise<SurveyRespondent> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/survey/respondent/${surveyRespondentId}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<SurveyRespondent>(response)
        });
}

export function getSurveyRespondentSubmits(surveyRespondentId: number): Promise<SurveySubmit[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/survey/respondent/${surveyRespondentId}/submit/list`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<SurveySubmit[]>(response)
        });
}