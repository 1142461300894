import { HttpMethod, requestOptions } from "../../../utils/api/request-options";
import { handleResponse } from "../../../utils/api/response-handler";
import { CohortStudentAverageRating } from "../model/cohort-student-average-rating";
import { CohortStudentModuleAverageRating } from "../model/cohort-student-module-average-rating";
import { CohortStudentModuleBadgeRating } from "../model/cohort-student-module-badge-rating";
import { CohortStudentModuleBadgeResubmit } from "../model/cohort-student-module-badge-resubmit";
import { CohortStudentModuleBadgeUnevaluated } from "../model/cohort-student-module-badge-unevaluated";
import { CohortStudentModuleBadgeUnsubmitted } from "../model/cohort-student-module-badge-unsubmitted";

export function getCohortModuleAverageRatings(
    cohortId: number,
    moduleId?: string | undefined,
    studentId?: number | undefined
): Promise<CohortStudentModuleAverageRating[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/dashboard/manager/cohort/${cohortId}/module/rating/average?moduleId=${moduleId ?? ''}&studentId=${studentId ?? ''}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<CohortStudentModuleAverageRating[]>(response)
        });
}

export function getCohortStudentAverageRatings(
    cohortId: number,
    studentId?: number | undefined
): Promise<CohortStudentAverageRating[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/dashboard/manager/cohort/${cohortId}/student/rating/average?studentId=${studentId ?? ''}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<CohortStudentAverageRating[]>(response)
        });
}

export function getCohortBadgeRatings(
    cohortId: number,
    moduleId?: string | undefined,
    studentId?: number | undefined
): Promise<CohortStudentModuleBadgeRating[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/dashboard/manager/cohort/${cohortId}/badge/rating?moduleId=${moduleId ?? ''}&studentId=${studentId ?? ''}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<CohortStudentModuleBadgeRating[]>(response)
        });
}

export function getCohortUnsubmittedBadges(
    cohortId: number,
    moduleId?: string | undefined,
    studentId?: number | undefined
): Promise<CohortStudentModuleBadgeUnsubmitted[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/dashboard/manager/cohort/${cohortId}/badge/unsubmitted?moduleId=${moduleId ?? ''}&studentId=${studentId ?? ''}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<CohortStudentModuleBadgeUnsubmitted[]>(response)
        });
}

export function getCohortUnevaluatedBadges(
    cohortId: number,
    moduleId?: string | undefined,
    studentId?: number | undefined
): Promise<CohortStudentModuleBadgeUnevaluated[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/dashboard/manager/cohort/${cohortId}/badge/unevaluated?moduleId=${moduleId ?? ''}&studentId=${studentId ?? ''}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<CohortStudentModuleBadgeUnevaluated[]>(response)
        });
}

export function getCohortResubmitBadges(
    cohortId: number,
    moduleId?: string | undefined,
    studentId?: number | undefined
): Promise<CohortStudentModuleBadgeResubmit[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/dashboard/manager/cohort/${cohortId}/badge/resubmit?moduleId=${moduleId ?? ''}&studentId=${studentId ?? ''}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<CohortStudentModuleBadgeResubmit[]>(response)
        });
}