import { GridColDef } from "@mui/x-data-grid";

export const columns: GridColDef[] = [
    { field: 'identity', headerName: 'Identity', flex: 1 },
    { field: 'names', headerName: 'Name(s)', flex: 2, renderCell: (params) => { return renderNames(params.row.names) } },
    { field: 'submits', headerName: 'Submits', flex: 0.5 }
];

const renderNames = (names: string[]) => {
    return names.join(', ');
}