import { useCallback, useEffect, useRef } from 'react';

const GOOGLE_INIT_DELAY = 500;
const GOOGLE_INIT_TRIES = 5

const GoogleAuth = ({ authSuccessful }: { authSuccessful: Function}) => {
    const googleButton = useRef(null);
    let tries = 0;

    const initializeGoogleButton = useCallback(() => {
        if (tries < GOOGLE_INIT_TRIES) {
            try {
                google.accounts.id.initialize({
                    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                    callback: (response: any) => authSuccessful(response.credential),
                })
                google.accounts.id.renderButton(
                    googleButton.current,
                    { theme: 'outline', size: 'large', width: '240px' }
                )
            } catch (e) {
                console.log("Unable to initiate google auth, retrying...");
                tries++;
                setTimeout(() => { initializeGoogleButton() }, GOOGLE_INIT_DELAY);
            }
        } else {

        }
    }, [tries, authSuccessful]);

    useEffect(() => {
        tries++;
        setTimeout(() => { initializeGoogleButton() }, GOOGLE_INIT_DELAY);
    }, [tries, initializeGoogleButton])

    return (
        <div ref={googleButton} />
    )
}

export default GoogleAuth