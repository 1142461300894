import { FieldDefinition } from "../../../../component/form/field-definition";
import { SelectItem } from "../../../../component/form/select-item";
import { courseLevelOptions, groupGenderOptions } from "../../../../utils/form-select-input-options";
import { TargetGroup, targetGroups } from "../../enum/target-group";

const cohortTargetGroupOptions = () => {
    return targetGroups.map(_ => {
        let selectItem: SelectItem = {
            id: _,
            name: TargetGroup[_].replace('_', ' ')
        }
        return selectItem;
    });
}

export const cohortFieldDefinitions: FieldDefinition[] = [
    {
        id: "name",
        name: "Name",
        type: "text",
        required: true,
        disabled: false,
        min: 3,
        max: 63
    },
    {
        id: "year",
        name: "Year",
        type: "number",
        required: true,
        disabled: false
    },
    {
        id: "age",
        name: "Age",
        type: "number",
        adornment: "years old",
        required: true,
        disabled: false
    },
    {
        id: "courseLevelId",
        name: "Course level",
        type: "select",
        required: true,
        disabled: false,
        values: courseLevelOptions()
    },
    {
        id: "gender",
        name: "Gender",
        type: "select",
        required: true,
        disabled: false,
        values: groupGenderOptions()
    },
    {
        id: "targetGroups",
        name: "Target groups",
        type: "multi-select",
        required: true,
        disabled: false,
        values: cohortTargetGroupOptions()
    },
    {
        id: "active",
        name: "Active",
        type: "boolean",
        required: true,
        disabled: false
    }
];