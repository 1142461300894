import { requestOptions, HttpMethod } from "../../../utils/api/request-options";
import { handleResponse, handleResponseNoContent } from "../../../utils/api/response-handler";
import { Cohort } from "../model/cohort";
import { Module } from "../model/module";
import { ModulePrerequisite } from "../model/module-prerequisite";

export function getModules(courseLevelId?: number | undefined, active?: boolean | undefined): Promise<Module[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/module/list?courseLevelId=${courseLevelId ?? ''}&active=${active ?? ''}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<Module[]>(response)
        });
}

export function getModule(moduleId: string): Promise<Module> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/module/${moduleId}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<Module>(response)
        });
}

export function addModule(module: Module): Promise<Module> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/module`, requestOptions(HttpMethod.POST, module))
        .then(response => {
            return handleResponse<Module>(response)
        });
}

export function updateModule(module: Module): Promise<Module> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/module`, requestOptions(HttpMethod.PUT, module))
        .then(response => {
            return handleResponse<Module>(response)
        });
}

export function deleteModule(moduleId: string): Promise<void> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/module/${moduleId}`, requestOptions(HttpMethod.DELETE))
        .then(response => {
            return handleResponseNoContent(response)
        });
}

export function getModuleCohorts(moduleId: string): Promise<Cohort[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/module/${moduleId}/cohort`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<Cohort[]>(response)
        });
}


export function getModulePrerequisites(moduleId: string): Promise<ModulePrerequisite[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/module/${moduleId}/prerequisite`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<ModulePrerequisite[]>(response)
        });
}

export function updateModulePrerequisites(moduleId: string, modulePrerequisite: ModulePrerequisite[]): Promise<ModulePrerequisite[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/module/${moduleId}/prerequisite`, requestOptions(HttpMethod.GET, modulePrerequisite))
        .then(response => {
            return handleResponse<Promise<ModulePrerequisite[]>>(response)
        });
}