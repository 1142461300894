import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import StarIcon from '@mui/icons-material/Star';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { renderDate } from "../../../../utils/table-column-renders";
import { getNumber } from "../../../../utils/helper/string-helper";

export const columns: GridColDef[] = [
    { field: 'cohortName', headerName: 'Cohort', flex: 1,  },
    { field: 'moduleName', headerName: 'Module', flex: 1 },
    { field: 'badgeName', headerName: 'Badge', flex: 1 },
    { field: 'studentName', headerName: 'Student', flex: 1 },
    { field: 'evaluatorName', headerName: 'Evaluator', flex: 1 },
    { field: 'submissionDate', headerName: 'Submission', flex: 0.5, valueFormatter: (params) => { return renderDate(params.value, false) } },
    { field: 'evaluatedDate', headerName: 'Evaluation', flex: 0.5, valueFormatter: (params) => { return renderDate(params.value, false) } },
    { field: 'rating', headerName: 'Rating', flex: 0.5, renderCell: (params) => { return renderRating(params.row.rating) } },
    { field: 'hasComment', headerName: 'Comment', flex: 0.5, renderCell: (params) => { return renderComment(params.row.hasComment) } }
];

const renderRating = (rating?: string | undefined) => {
    return <React.Fragment>
        {getNumber(rating) > 0 && Array.from({ length: getNumber(rating) }, () => <StarIcon fontSize="small" key={`${Math.random()}-select-icon`} color="primary" sx={{ float: 'right' }} />)}
    </React.Fragment>
}

const renderComment = (comment?: string | undefined) => {
    if(comment !== undefined) {
        return <ChatBubbleIcon color="primary" sx={{ float: 'right' }} />
    } else {
        return <span></span>
    }
}