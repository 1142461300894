import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { columns } from "./evaluator-list-column-definition";
import commonStyles from "../../../style/common.module.css";
import { Evaluator, getCourseLevels, getModuleCategories } from "../../model/evaluator";
import { getEvaluators } from "../../service/evaluator-service";
import { EDUCATION_MANAGEMENT_EVALUATOR_MODULE_ASSIGN, PARAMETER_EVALUATOR_ID } from "../../../../infrastructure/route";
import { EVALUATOR_TAB_STATE, EVALUATOR_TAB_STATE_MODULES } from "../evaluator-view";
import { useCache } from "../../../../context/cache/cache-provider";
import { LoadingButton } from "@mui/lab";
import { DialogType, informationText } from "../../../../context/dialog/dialog-context-type";
import { useDialog } from "../../../../context/dialog/dialog-provider";
import { BaseList } from "../../../../component/list/base-list";

export default function EvaluatorList() {
    const [loading, setLoading] = useState(true);
    const [evaluators, setEvaluators] = useState<Evaluator[]>([]);

    const navigate = useNavigate();
    const cache = useCache();
    const dialog = useDialog();

    useEffect(() => {
        setLoading(true);
        getEvaluators().then(result => {

            (result ?? []).forEach(_ => {
                _.courseLevels = getCourseLevels(_.modules ?? []);
                _.moduleCategories = getModuleCategories(_.modules ?? []);
            })

            setEvaluators(result ?? []);
            setLoading(false);
        });
    }, []);

    const openModuleAssign = (evaluatorId: string) => {
        const evaluator = evaluators.find(_ => _.evaluatorId === Number(evaluatorId));
        cache.update({ key: PARAMETER_EVALUATOR_ID, value: evaluator?.evaluatorName ?? '' });

        localStorage.setItem(EVALUATOR_TAB_STATE, EVALUATOR_TAB_STATE_MODULES);
        navigate(EDUCATION_MANAGEMENT_EVALUATOR_MODULE_ASSIGN.replace(PARAMETER_EVALUATOR_ID, `${evaluatorId}`));
    }

    const openHelpInformationDialog = () => {
        dialog.openDialog(DialogType.INFORMATION, "Help / Information", [
            informationText("If you can't find a user in the list, make sure that he or she is <b>Active</b>, has the role <b>Evaluator</b>, and has an <b>E-mail</b>. Look up the user under <b>Account managment / Users</b>, or contact the <b>Administrator</b>.")
        ]);
    }

    return (
        <div className={commonStyles.content} >
            <BaseList
                storageKey="EVALUATOR"
                rows={evaluators}
                columns={columns}
                loading={loading}
                localeText={{ noRowsLabel: "No evaluators" }}
                getRowId={(row) => row?.evaluatorId}
                onRowClick={(e) => openModuleAssign(e.row?.evaluatorId)}
            />
            <div style={{ display: 'flex', justifyContent: 'end', paddingTop: '1rem' }}>
                <LoadingButton
                    id={`info-button`}
                    loadingPosition="start"
                    startIcon={<HelpOutlineIcon />}
                    variant="outlined"
                    onClick={() => openHelpInformationDialog()}
                >
                    Info / Help
                </LoadingButton>
            </div>
        </div>
    );
}
