import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../../context/toast/toast-provider";
import BaseForm from "../../../../component/form/base-form";
import styles from "../../../style/crud-control.module.css"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from "@mui/material";
import { WorkItemAssignmentDetalis } from "../../model/work-item-assignment-details";
import { deleteWorkItemAssignment, reassignEvaluatorAssignment } from "../../service/assignment-service";
import { assignmentFieldDefinitions } from "./assignment-field-definition";
import { EDUCATION_MANAGEMENT_COHORT_EDIT, EDUCATION_MANAGEMENT_MODULE_BADGE_EDIT, EDUCATION_MANAGEMENT_MODULE_EDIT, PARAMETER_BADGE_ID, PARAMETER_COHORT_ID, PARAMETER_MODULE_ID } from "../../../../infrastructure/route";
import { getEvaluators } from "../../../-education/service/evaluator-service";
import { Evaluator } from "../../../-education/model/evaluator";
import ViewLoader from "../../../../component/misc/view-loader";
import { SelectItem } from "../../../../component/form/select-item";
import { AssignmentStatus } from "../../enum/assignment-status";

export default function AssignmentHandler({
    originUrl,
    workItemAssignmentDetalis,
    setWorkItemAssignmentDetalis
}: {
    originUrl: string,
    workItemAssignmentDetalis: WorkItemAssignmentDetalis,
    setWorkItemAssignmentDetalis: React.Dispatch<React.SetStateAction<WorkItemAssignmentDetalis | undefined>>
}) {
    const [loading, setLoading] = useState(true);
    const [reassigning, setReassigning] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [evaluators, setEvaluators] = useState<Evaluator[]>([]);

    const toast = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        getEvaluators(workItemAssignmentDetalis.moduleId).then(result => {
            setEvaluators(result);
            setLoading(false);
        })
    }, [workItemAssignmentDetalis.moduleId]);

    const canReassign = () => {
        return getEvaluatorSelectItems().filter(_ => _.id !== workItemAssignmentDetalis.evaluatorId).length >= 1 &&
            workItemAssignmentDetalis.assignmentStatus !== AssignmentStatus.Evaluated_Rated &&
            workItemAssignmentDetalis.assignmentStatus !== AssignmentStatus.Closed;
    }

    const getEvaluatorSelectItems = () => {
        return [{
            id: workItemAssignmentDetalis.evaluatorId,
            name: workItemAssignmentDetalis.evaluatorName
        } as SelectItem].concat(evaluators
            .filter(_ => _.evaluatorId !== workItemAssignmentDetalis.evaluatorId)
            .map(_ => {
                return {
                    id: _.evaluatorId,
                    name: _.evaluatorName
                } as SelectItem
            }));
    }

    const getWorkItemAssignmentDetalis = () => {
        const workItemAssignmentDetalisUpdate = {...workItemAssignmentDetalis};

        if(workItemAssignmentDetalisUpdate?.cohortId !== undefined) {
            workItemAssignmentDetalisUpdate.cohortLink = `${EDUCATION_MANAGEMENT_COHORT_EDIT
                .replace(PARAMETER_COHORT_ID, workItemAssignmentDetalisUpdate?.cohortId.toString())}`;
        }

        if(workItemAssignmentDetalisUpdate?.moduleId !== undefined) {
            workItemAssignmentDetalisUpdate.moduleLink = `${EDUCATION_MANAGEMENT_MODULE_EDIT
                .replace(PARAMETER_MODULE_ID, workItemAssignmentDetalisUpdate.moduleId)}`;
        }

        if(workItemAssignmentDetalisUpdate?.moduleId !== undefined && workItemAssignmentDetalisUpdate?.badgeId !== undefined) {
            workItemAssignmentDetalisUpdate.badgeLink = `${EDUCATION_MANAGEMENT_MODULE_BADGE_EDIT
                .replace(PARAMETER_MODULE_ID, workItemAssignmentDetalisUpdate.moduleId)
                .replace(PARAMETER_BADGE_ID, workItemAssignmentDetalisUpdate.badgeId)}`;
        }

        workItemAssignmentDetalisUpdate.assignmentStatusName = AssignmentStatus[workItemAssignmentDetalisUpdate.assignmentStatus]
            .replace("_", " ");

        return workItemAssignmentDetalisUpdate
    }

    const getAssignmentFieldDefinitions = () => {
        assignmentFieldDefinitions.find(_ => _.id === "cohortName")!.hidden = workItemAssignmentDetalis.cohortLink === undefined;
        assignmentFieldDefinitions.find(_ => _.id === "cohortLink")!.hidden = workItemAssignmentDetalis.cohortLink !== undefined;
        assignmentFieldDefinitions.find(_ => _.id === "cohortLink")!.valueName = workItemAssignmentDetalis.cohortName;

        assignmentFieldDefinitions.find(_ => _.id === "moduleName")!.hidden = workItemAssignmentDetalis.moduleLink === undefined;
        assignmentFieldDefinitions.find(_ => _.id === "moduleLink")!.hidden = workItemAssignmentDetalis.moduleLink !== undefined;
        assignmentFieldDefinitions.find(_ => _.id === "moduleLink")!.valueName = workItemAssignmentDetalis.moduleName;

        assignmentFieldDefinitions.find(_ => _.id === "badgeName")!.hidden = workItemAssignmentDetalis.badgeLink === undefined;
        assignmentFieldDefinitions.find(_ => _.id === "badgeLink")!.hidden = workItemAssignmentDetalis.badgeLink !== undefined;
        assignmentFieldDefinitions.find(_ => _.id === "badgeLink")!.valueName = workItemAssignmentDetalis.badgeName;

        assignmentFieldDefinitions.find(_ => _.id === "canResubmit")!.hidden = workItemAssignmentDetalis.badgeAllowResubmit === false;
        assignmentFieldDefinitions.find(_ => _.id === "resubmits")!.hidden = workItemAssignmentDetalis.badgeAllowResubmit === false;
        assignmentFieldDefinitions.find(_ => _.id === "resubmittDate")!.hidden = workItemAssignmentDetalis.badgeAllowResubmit === false;

        assignmentFieldDefinitions.find(_ => _.id === "newEvaluatorId")!.hidden = canReassign() === false;
        assignmentFieldDefinitions.find(_ => _.id === "newEvaluatorId")!.values = getEvaluatorSelectItems();

        return assignmentFieldDefinitions;
    }

    const handleReassign = (workItemAssignmentDetalis: WorkItemAssignmentDetalis) => {
        if(workItemAssignmentDetalis.newEvaluatorId !== undefined && workItemAssignmentDetalis.evaluatorId !== workItemAssignmentDetalis.newEvaluatorId) {
            setReassigning(true);

            reassignEvaluatorAssignment(workItemAssignmentDetalis.workItemId, workItemAssignmentDetalis.newEvaluatorId).then(() => {
                const newEvaluator = evaluators.find(_ => _.evaluatorId === workItemAssignmentDetalis.newEvaluatorId);

                const workItemAssignmentDetalisUpdate = {...workItemAssignmentDetalis};
                workItemAssignmentDetalisUpdate.evaluatorId = newEvaluator?.evaluatorId;
                workItemAssignmentDetalisUpdate.evaluatorName = newEvaluator?.evaluatorName;

                setWorkItemAssignmentDetalis(workItemAssignmentDetalisUpdate);

                toast.addToast(`Assignment has been reassigned to ${newEvaluator?.evaluatorName}`, "success");
                setReassigning(false);
            }).catch(() => {
                toast.addToast(`Unable to reassign assignment ${workItemAssignmentDetalis.badgeName}`, "error");
                setReassigning(false);
            });
        }
    }

    const handleDelete = () => {
        setDeleting(true);
        deleteWorkItemAssignment(workItemAssignmentDetalis.workItemId).then(() => {
            toast.addToast(`Assignment ${workItemAssignmentDetalis.badgeName} deleted`, "success");
            setDeleting(false);
            navigate(originUrl);
        }).catch(() => {
            toast.addToast(`Unable to delete assignment ${workItemAssignmentDetalis.badgeName}`, "error");
            setDeleting(false);
        });
    }

    return (
        <React.Fragment>
            <ViewLoader loading={loading} />
            {loading === false && <div className={styles.top}>
                <div className={styles.control} >
                    <Button
                        variant="contained"
                        startIcon={<ArrowBackIcon />}
                        onClick={() => navigate(originUrl)}
                    >
                        Back to Assignments
                    </Button>
                </div>
            </div>}
            {loading === false && <BaseForm
                name={workItemAssignmentDetalis.badgeName}
                model={getWorkItemAssignmentDetalis()}
                setModel={setWorkItemAssignmentDetalis}
                fields={getAssignmentFieldDefinitions()}
                submitEntity={canReassign() === true ? handleReassign : undefined}
                deleteEntity={handleDelete}
                submitting={reassigning}
                submitButtonText="Reassign"
                deleting={deleting}
            />}
        </React.Fragment>
    );
}
