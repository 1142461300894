import { useEffect, useRef, useState } from "react";
import styles from "../style/public.module.css";
import loginLion from "../../../assets/survey/loginLion.png";
import noAccessLion from "../../../assets/survey/noAccessLion.png";
import successSubmitLion from "../../../assets/survey/successSubmitLion.png";
import surveyNotFoundLion from "../../../assets/survey/surveyNotFoundLion.png";
import userNotFoundLion from "../../../assets/survey/userNotFoundLion.png";
import { useParams } from "react-router-dom";
import ForwardIcon from '@mui/icons-material/Forward';
import ReplayIcon from '@mui/icons-material/Replay';
import { SurveyAnswerType } from "../../-examine/enum/survey-answer-type";
import { surveyRespondentFieldDefinitions } from "./survey-respondent-field-definition";
import { SurveySubmitAnswer } from "../../-examine/model/survey-submit-answer";
import { SurveySubmit } from "../../-examine/model/survey-submit";
import { useToast } from "../../../context/toast/toast-provider";
import { getSurvey, surveyRequireLogin, surveySubmit } from "../service/public-service";
import dayjs from "dayjs";
import { FieldDefinition } from "../../../component/form/field-definition";
import { SelectItem } from "../../../component/form/select-item";
import BaseForm from "../../../component/form/base-form";
import { SurveyState } from "../../-examine/enum/survey-state";
import { SurveyPublic } from "../../-examine/model/survey-public";
import { ApiErrorType } from "../../../utils/api/api-error-type";
import { SurveyLogin } from "../model/survey-login";
import { surveyLoginFieldDefinitions } from "./survey-login-field-definition";
import { LoadingButton } from "@mui/lab";
import ViewLoader from "../../../component/misc/view-loader";
import { SurveySubmitRespondentInfo } from "../../-examine/model/survey-submit-respondent-info";
import { validateEmail, validatePhone } from "../../../component/form/input-validation";

interface Answers {
    [key: string]: any
}

export default function SurveyForm() {
    const { surveyId } = useParams();
    let survey = useRef<SurveyPublic | undefined>();

    const [loading, setLoading] = useState<boolean>(true);
    const [loginRequired, setLoginRequired] = useState<boolean | undefined>();
    const [surveyLogin, setSurveyLogin] = useState<SurveyLogin>({} as SurveyLogin);
    const [loginEmail, setLoginEmail] = useState<string | undefined>();
    const [getSurveyErrorType, setGetSurveyErrorType] = useState<ApiErrorType | undefined>();
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submitErrors, setSubmitErrors] = useState<any | undefined>();
    const [answers, setAnswers] = useState<Answers>();
    const [respondent, setRespondent] = useState<SurveySubmitRespondentInfo>({})
    const [questions, setQuestions] = useState<FieldDefinition[]>([]);

    const toast = useToast();

    useEffect(() => {
        if (surveyId !== undefined) {
            setLoading(true);
            surveyRequireLogin(surveyId).then(result => {
                setLoginRequired(result);
                setLoading(false);
            }).catch((error) => {
                if (error?.apiErrorResponse?.errorCode === 404) {
                    setGetSurveyErrorType(ApiErrorType.Survey_Public_SurveyNotFound)
                }
                setLoginRequired(true);
                setLoading(false);
            });
        }
    }, [surveyId])

    useEffect(() => {
        if(surveyId !== undefined && loginRequired !== undefined && (loginRequired === false || loginEmail !== undefined) && survey.current === undefined) {
            setLoading(true);
            getSurvey(surveyId, loginEmail).then(result => {
                let surveyAnswers = {} as Answers
                const surveyQuestions = result.questions.map(_ => {

                    surveyAnswers[`Q_${_.answerType}_${_.sequence}`] = _.answerType === SurveyAnswerType.Boolean ? false : "";

                    const values = _.answers.map(a => {
                        return {
                            id: a.text,
                            name: a.text
                        } as SelectItem
                    });

                    return {
                        id: `Q_${_.answerType}_${_.sequence}`,
                        name: _.title,
                        type: SurveyAnswerType[_.answerType].replace('_', '-').toLowerCase(),
                        required: _.answerRequired,
                        disabled: false,
                        values: values
                    } as FieldDefinition
                });

                setQuestions(surveyQuestions);
                setAnswers(surveyAnswers);

                if (result.actor !== undefined) {
                    let updatedRespondent = { ...respondent, name: result.actor.name };
                    surveyRespondentFieldDefinitions.find(_ => _.id === "name")!.disabled = true;
        
                    if (result.actor.emailAddress !== undefined) {
                        updatedRespondent = { ...updatedRespondent, emailAddress: result.actor.emailAddress };
                        surveyRespondentFieldDefinitions.find(_ => _.id === "emailAddress")!.disabled = true;
                    }
        
                    if (result.actor.phoneNumber !== undefined) {
                        updatedRespondent = { ...updatedRespondent, phoneNumber: result.actor.phoneNumber };
                        surveyRespondentFieldDefinitions.find(_ => _.id === "phoneNumber")!.disabled = true;
                    }
        
                    setRespondent(updatedRespondent);
                }
    
                survey.current = result;
                setLoading(false);
            }).catch((error) => {
                if (error?.apiErrorResponse?.errorType !== undefined) {
                    setGetSurveyErrorType(error?.apiErrorResponse?.errorType as ApiErrorType)
                }
                setLoading(false);
            });
        }
    }, [surveyId, loginRequired, loginEmail, respondent, setRespondent]);

    const getSurveyRespondentFieldDefinitions = () => {
        return surveyRespondentFieldDefinitions;
    }

    const getQuestionSequence = (id: string) => {
        const lastSection = id.lastIndexOf('_');
        return Number(id.substring(lastSection + 1));
    }

    const getAnswerString = (id: string, answer: any) => {
        const firstSection = id.replace('Q_', '').indexOf('_');
        const answerType = Number(id.replace('Q_', '').substring(0, firstSection));

        switch (answerType) {
            case SurveyAnswerType.Text:
            case SurveyAnswerType.Text_Area:
            case SurveyAnswerType.Select:
                return answer as string;
            case SurveyAnswerType.Radio:
                return `${answer}`;
            case SurveyAnswerType.Multi_Select:
                const answerList = (answer as string[]);
                return answerList.join(', ');
            case SurveyAnswerType.Date:
                return dayjs(answer as string).format('YYYY-MM-DD');
            case SurveyAnswerType.Boolean:
                const answerBool = (answer as boolean);
                return answerBool === true ? 'True' : 'False';
            default:
                return ''
        }
    }

    const submitValidation = (_answers: Answers, errors: any) => {
        if (respondent.name === undefined || respondent.name === null || respondent.name.length === 0) {
            errors = { ...errors, name: `Name is required` };
        }

        if (respondent.phoneNumber === undefined || respondent.phoneNumber === null || respondent.phoneNumber.length === 0) {
            errors = { ...errors, phoneNumber: `Phone number is required` };
        } else if (respondent.phoneNumber !== undefined && respondent.phoneNumber !== null && !validatePhone(respondent.phoneNumber)) {
            errors = { ...errors, phoneNumber: `Not a vaild Phone number` };
        }

        if (respondent.emailAddress === undefined || respondent.emailAddress === null || respondent.emailAddress.length === 0) {
            errors = { ...errors, emailAddress: `Email address is required` };
        } else if (respondent.emailAddress !== undefined && respondent.emailAddress !== null && !validateEmail(respondent.emailAddress)) {
            errors = { ...errors, emailAddress: `Not a vaild Email` };
        }

        setSubmitErrors(errors)
        return errors;
    }

    const handleSubmit = (answers: Answers) => {
        if(surveyId !== undefined) {
            setSubmitting(true);

            const surveySubmitAnswer = [] as SurveySubmitAnswer[]

            for (var prop in answers) {
                if (Object.prototype.hasOwnProperty.call(answers, prop)) {
                    const sequence = getQuestionSequence(prop);
                    if (!isNaN(sequence)) {
                        surveySubmitAnswer.push({
                            surveyQuestionId: survey.current?.questions.find(_ => _.sequence === sequence)?.id,
                            answer: getAnswerString(prop, answers[prop])
                        });
                    }
                }
            }

            const newSurveySubmit = {
                surveyId: surveyId,
                actorId: survey.current?.actor?.id,
                name: respondent.name,
                phoneNumber: respondent.phoneNumber?.replace(/ /g, ''),
                emailAddress: respondent.emailAddress,
                answers: surveySubmitAnswer
            } as SurveySubmit;

            surveySubmit(newSurveySubmit).then(() => {
                setSubmitting(false);
                setSubmitted(true);
            }).catch((error) => {
                if (error?.apiErrorResponse?.errorType !== undefined) {
                    setGetSurveyErrorType(error?.apiErrorResponse?.errorType as ApiErrorType)
                } else {
                    toast.addToast(`Unable to submit survey :( Try again later!`, "error");
                }
                setSubmitting(false);
            });
        }
    }

    const getSurveyState = () => {
        if (loading === true || submitting === true) {
            return SurveyState.Loading;
        } else if (getSurveyErrorType !== null && getSurveyErrorType === ApiErrorType.Survey_Public_NoAccess) {
            return SurveyState.NoAccess;
        } else if (getSurveyErrorType !== null && getSurveyErrorType === ApiErrorType.Survey_Public_ActorNotFound) {
            return SurveyState.UserNotFound;
        } else if (getSurveyErrorType !== null && getSurveyErrorType === ApiErrorType.Survey_Public_SurveyNotFound) {
            return SurveyState.SurveyNotFound;
        } else if (getSurveyErrorType !== null && getSurveyErrorType === ApiErrorType.Survey_Public_SurveyAlreadySubmitted) {
            return SurveyState.SurveyAlreadySubmitted;
        } else if (loginRequired === true && loginEmail === undefined) {
            return SurveyState.LoginRequired;
        } else if (submitted === true) {
            return SurveyState.Submitted;
        } else if (survey.current !== undefined) {
            return SurveyState.Ready;
        }
    }

    return (
        <div className={styles.surveyContent} >
            <div className={styles.survey}>
                <ViewLoader loading={loading === true || submitting === true} />
                {getSurveyState() === SurveyState.Ready && <div style={{paddingTop: "4rem", paddingBottom: "4rem"}}>
                    <h1>{survey.current?.title}</h1>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: survey.current?.description.replace(/\n/g, '<br />') ?? ''
                        }}>
                    </p>
                    <br />
                    <h3 style={{marginTop: '2rem'}}>Your information</h3>
                    <BaseForm
                        name={`respondent_${survey.current?.title}`}
                        model={respondent}
                        setModel={setRespondent}
                        fields={getSurveyRespondentFieldDefinitions()}
                        inputFull={true}
                        knownValidationErrors={submitErrors}
                    />
                    <h3 style={{marginTop: '2rem'}}>Questions</h3>
                    <BaseForm
                        name={`answers_${survey.current?.title}`}
                        model={answers}
                        setModel={setAnswers}
                        fields={questions}
                        inputFull={true}
                        submitValidation={submitValidation}
                        submitEntity={handleSubmit}
                        submitButtonText={'Submit survey'}
                        submitting={submitting}
                    />
                </div>}
                {getSurveyState() === SurveyState.LoginRequired && <div className={styles.surveyInfo} >
                    <div className={styles.surveyLogin}>
                        <div>
                            <img src={loginLion} alt='Login lion' />
                        </div>
                        <h1>Who are you?</h1>
                        <p>Please enter your <b>email</b> so we can find you!</p>
                        <BaseForm
                            name="Email"
                            model={surveyLogin}
                            setModel={setSurveyLogin}
                            fields={surveyLoginFieldDefinitions}
                        />
                        <LoadingButton
                            sx={{width: "180px"}}
                            loadingPosition="end"
                            endIcon={<ForwardIcon />}
                            variant="contained"
                            onClick={() => {
                                setLoginEmail(surveyLogin.email);
                            }}
                        >
                            Go to survey
                        </LoadingButton>
                    </div>
                </div>}
                {getSurveyState() === SurveyState.UserNotFound && <div className={styles.surveyInfo} >
                    <div className={styles.surveyLogin}>
                        <div>
                            <img src={userNotFoundLion} alt='User not found lion' />
                        </div>
                        <h1>We can't find you!</h1>
                        <p>Are you suuuure you wrote the correct <b>email</b>? Try again!</p>
                        <LoadingButton
                            sx={{width: "180px", marginTop: "2rem"}}
                            loadingPosition="end"
                            endIcon={<ReplayIcon />}
                            variant="contained"
                            onClick={() => {
                                setLoginEmail(undefined);
                                setGetSurveyErrorType(undefined);
                            }}
                        >
                            Try again
                        </LoadingButton>
                    </div>
                </div>}
                {getSurveyState() === SurveyState.SurveyNotFound && <div className={styles.surveyInfo} >
                    <div>
                        <div>
                            <img src={surveyNotFoundLion} alt='User not found lion' />
                        </div>
                        <h1>Survey not found!</h1>
                        <p>The survey you are requesting.... We can't find it...<br />Make sure you have to correct link!</p>
                    </div>
                </div>}
                {getSurveyState() === SurveyState.NoAccess && <div className={styles.surveyInfo} >
                    <div>
                        <div>
                            <img src={noAccessLion} alt='No access lion' />
                        </div>
                        <h1>No no no!</h1>
                        <p>You are not allowed for take part in this <b>survey</b>!</p>
                    </div>
                </div>}
                {getSurveyState() === SurveyState.SurveyAlreadySubmitted && <div className={styles.surveyInfo} >
                    <div>
                        <div>
                            <img src={noAccessLion} alt='Survey already submitted lion' />
                        </div>
                        <h1>No no no!</h1>
                        <p>You have already submitted this <b>survey</b>!</p>
                    </div>
                </div>}
                {getSurveyState() === SurveyState.Submitted && <div className={styles.surveyInfo} >
                    <div>
                        <div>
                            <img src={successSubmitLion} alt='Successs submit lion' />
                        </div>
                        <h1>Thank you!</h1>
                        <p>We at Learning Lion are super grateful to recieve your submitted survey!<br /><br />Have a great day!</p>
                    </div>
                </div>}
            </div>
        </div>
    );
}
