import React from 'react';
import styles from './style/misc.module.css';
import { CircularProgress } from "@mui/material";

export default function ViewLoader({ loading }: { loading: boolean }) {

    return (
        <React.Fragment>
            {loading === true && <div className={styles.loading} >
                <div>
                    <CircularProgress style={{ color: 'var(--color-primary)' }} />
                </div>
            </div>}
        </React.Fragment>
    )
}