import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import commonStyles from "../../../style/common.module.css";
import { Dayjs } from "dayjs";
import { EXAMINE_SURVEY_SUBMIT_VIEW, PARAMETER_SURVEY_SUBMIT_ID } from "../../../../infrastructure/route";
import { useCache } from "../../../../context/cache/cache-provider";
import { getUserSurveySubmits, getSurveySubmits } from "../../service/survey-submit-service";
import { columns } from "./survey-submit-list-column-definition";
import { BaseList } from "../../../../component/list/base-list";
import { SurveySubmitOverview } from "../../model/survey-submit-overview";

export default function SurveySubmitList({
    userId
}: {
        userId?: number | undefined
}) {
    const [loading, setLoading] = useState(true);
    const [surveySubmits, setSurveySubmits] = useState<SurveySubmitOverview[]>([]);
    const [from, setFrom] = useState<Dayjs | null>(null);
    const [to, setTo] = useState<Dayjs | null>(null);

    const navigate = useNavigate();
    const cache = useCache();

    useEffect(() => {
        if (userId !== undefined) {
            setLoading(true);
            getUserSurveySubmits(userId).then(result => {
                setSurveySubmits(result ?? []);
                setLoading(false);
            });
        } else if (from !== null && to !== null && to.diff(from, 'day') >= 0) {
            setLoading(true);
            getSurveySubmits(from.format('YYYY-MM-DD'), to.add(1, 'day').format('YYYY-MM-DD')).then(result => {
                setSurveySubmits(result ?? []);
                setLoading(false);
            });
        }
    }, [from, to, userId]);

    const openRead = (surveySubmitId: string) => {
        const surveySubmit = surveySubmits.find(_ => _.id === Number(surveySubmitId));
        cache.update({ key: PARAMETER_SURVEY_SUBMIT_ID, value: surveySubmit?.surveyTitle ?? '' });

        navigate(EXAMINE_SURVEY_SUBMIT_VIEW.replace(PARAMETER_SURVEY_SUBMIT_ID, `${surveySubmitId}`));
    }

    return (
        <div className={userId === undefined ? commonStyles.content : ''}>
            <BaseList
                storageKey="SURVEY_SUBMIT"
                rows={surveySubmits}
                columns={columns}
                loading={loading}
                localeText={{ noRowsLabel: "No submitted surveys" }}
                getRowId={(row) => `${row?.id}`}
                onRowClick={(e) => openRead(e.row?.id)}
                from={userId === undefined ? from : undefined}
                to={userId === undefined ? to : undefined}
                setFrom={userId === undefined ? setFrom : undefined}
                setTo={userId === undefined ? setTo : undefined}
            />
        </div>
    );
}
