import { SelectItem } from "../../../../component/form/select-item";
import { BadgeAssignment } from "../../model/badge-assignment";
import { ModuleAssignment } from "../../model/module-assignment";
import { getSelectedModule } from "../../utils/assignment-auto-select";
import { ASSIGNMENT_QUERY_PARAMETER_WORKITEMID, ASSIGNMENT_QUERY_PARAMETER_MODULEID } from "../../utils/assignment-constants";

export interface AssignmentFilter {
    moduleId?: string | undefined,
    workItemId?: number | undefined
}

export const getUnreviewedWorkItems = (unreviewedModuleAssignment?: ModuleAssignment) => {
    return unreviewedModuleAssignment?.badges.filter(_ => 
        _.submissionDate !== undefined &&
        _.evaluatedDate === undefined) ?? [] as BadgeAssignment[]
}

export const getSelectedWorkItem = (
    unreviewedModuleAssignment?: ModuleAssignment,
    searchParams?: URLSearchParams | undefined
) => {
    const workItemId = Number(searchParams?.get(ASSIGNMENT_QUERY_PARAMETER_WORKITEMID));
    if (unreviewedModuleAssignment !== undefined && workItemId !== null && workItemId !== undefined && workItemId !== 0) {
        return unreviewedModuleAssignment.badges.find(_ => _.workItemId === workItemId)
    } else if (unreviewedModuleAssignment !== undefined && getUnreviewedWorkItems(unreviewedModuleAssignment).length === 1) {
        return getUnreviewedWorkItems(unreviewedModuleAssignment)[0];
    } else {
        return undefined
    }
}

export const getFilter = (
    unreviewedModuleAssignments: ModuleAssignment[],
    searchParams: URLSearchParams
) => {
    const unreviewedModuleAssignment = getSelectedModule(unreviewedModuleAssignments, searchParams);
    const unreviewedWorkItemAssignment = getSelectedWorkItem(unreviewedModuleAssignment, searchParams);

    searchParams.delete(ASSIGNMENT_QUERY_PARAMETER_WORKITEMID);
    searchParams.delete(ASSIGNMENT_QUERY_PARAMETER_MODULEID);

    return {
        moduleId: unreviewedModuleAssignment?.moduleId,
        workItemId: unreviewedWorkItemAssignment?.workItemId
    } as AssignmentFilter;
}

export const getUnreviewedWorkItemSelectItems = (selectedModule: ModuleAssignment) => {
    return getUnreviewedWorkItems(selectedModule).map(_ => {
        let selectItem: SelectItem = {
            id: _.workItemId,
            name: _.badgeName ?? `Badge ${_.sequence}`
        }
        return selectItem;
    });
}