import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { applicantFieldDefinitions } from "./applicant-field-definition";
import { User } from "../../model/user";
import { useToast } from "../../../../context/toast/toast-provider";
import { ACCOUNT_MANAGEMENT_APPLICANT_EDIT, ACCOUNT_MANAGEMENT_APPLICANT_LIST, PARAMETER_APPLICANT_ID } from "../../../../infrastructure/route";
import BaseForm from "../../../../component/form/base-form";
import { addUserApplicant, deleteUserApplicant, updateUserApplicant } from "../../service/applicant-service";
import React from "react";
import { isApplicant } from "../../../enum/role";

export default function ApplicantHandler({
    newModel,
    applicant,
    setApplicant
}: {
        newModel: boolean,
        applicant: User,
        setApplicant: React.Dispatch<React.SetStateAction<User>>
}) {
    const [submitting, setSubmitting] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const toast = useToast();
    const navigate = useNavigate();

    const getApplicantFieldDefinitions = () => {
        if(isApplicant(applicant.roles) === false && newModel === false) {
            applicantFieldDefinitions.find(_ => _.id === "name")!.disabled = true;
            applicantFieldDefinitions.find(_ => _.id === "phone")!.disabled = true;
            applicantFieldDefinitions.find(_ => _.id === "email")!.disabled = true;
            applicantFieldDefinitions.find(_ => _.id === "gender")!.disabled = true;
            applicantFieldDefinitions.find(_ => _.id === "gender")!.required = false;
            applicantFieldDefinitions.find(_ => _.id === "active")!.disabled = true;
        } else {
            applicantFieldDefinitions.find(_ => _.id === "name")!.disabled = false;
            applicantFieldDefinitions.find(_ => _.id === "phone")!.disabled = false;
            applicantFieldDefinitions.find(_ => _.id === "email")!.disabled = false;
            applicantFieldDefinitions.find(_ => _.id === "gender")!.disabled = false;
            applicantFieldDefinitions.find(_ => _.id === "gender")!.required = true;
            applicantFieldDefinitions.find(_ => _.id === "active")!.disabled = false;
        }

        return applicantFieldDefinitions;
    }

    const deleteWarnings = () => {
        const warnings = [
            applicant.hasSurveySubmits === true ? `<b>${applicant.name}</b> submitted <b>surveys</b>, deleting the user will also <b>delete the survey submits</b>.` : '',
        ].filter(_ => _.length > 0)

        return warnings.length > 0 ? warnings : undefined;
    }

    const handleSubmit = (applicantUser: User) => {
        setSubmitting(true);

        if (newModel) {
            addUserApplicant(applicantUser).then(result => {
                toast.addToast(`Applicant ${result.name} created`, "success");
                setApplicant(result);
                setSubmitting(false);

                navigate(ACCOUNT_MANAGEMENT_APPLICANT_EDIT.replace(PARAMETER_APPLICANT_ID, `${result.id}`))
            }).catch((error) => {
                if (error?.apiErrorResponse?.errorCode === 409) {
                    toast.addToast(`The email ${applicantUser.email} already exists`, "error");
                } else {
                    toast.addToast(`Unable to create applicant`, "error");
                }
                setSubmitting(false);
            });
        } else {
            updateUserApplicant(applicantUser).then(result => {
                toast.addToast(`Applicant ${result.name} updated`, "success");
                setApplicant(result);
                setSubmitting(false);
            }).catch((error) => {
                if (error?.apiErrorResponse?.errorCode === 409) {
                    toast.addToast(`The email ${applicantUser.email} already exists`, "error");
                } else {
                    toast.addToast(`Unable to update applicant ${applicantUser.name}`, "error");
                }
                setSubmitting(false);
            });
        }
    }

    const handleDelete = () => {
        if (!newModel) {
            setDeleting(true);
            deleteUserApplicant(Number(applicant.id)).then(() => {
                toast.addToast(`Applicant ${applicant.name} deleted`, "success");
                setDeleting(false);
                navigate(ACCOUNT_MANAGEMENT_APPLICANT_LIST);
            }).catch(() => {
                toast.addToast(`Unable to delete applicant ${applicant.name}`, "error");
                setDeleting(false);
            });
        }
    }

    return (
        <BaseForm
            name={applicant.name}
            model={applicant}
            setModel={setApplicant}
            fields={getApplicantFieldDefinitions()}
            submitEntity={handleSubmit}
            deleteEntity={!newModel && isApplicant(applicant.roles) === true ? handleDelete : undefined}
            submitting={submitting}
            deleting={deleting}
            deleteWarnings={deleteWarnings()}
        />
    );
}
