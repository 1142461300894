import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ViewLoader from "../../../component/misc/view-loader";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useCache } from "../../../context/cache/cache-provider";
import { PARAMETER_ATTENDANCE_DAY } from "../../../infrastructure/route";
import { getCohorts } from "../service/cohort-service";
import { Cohort } from "../model/cohort";
import { AttendanceUpdate } from "../model/attendance-update";
import { ATTENDANCE_CACHE_COHORT_ID } from "./-attendance-list/attendance-list";
import { User } from "../../-account/model/user";
import AttendanceHandler from "./-attendance-handler/attendance-handler";
import { getUserStudents } from "../../-account/service/student-service";

const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

export const ATTENDANCE_TAB_STATE: string = "ATTENDANCE_TAB_STATE";
export const ATTENDANCE_TAB_STATE_UPDATE: string = "ATTENDANCE_TAB_STATE_UPDATE";

export default function AttendanceView() {
    const { day } = useParams();
    const cache = useCache();

    const [tab, setTab] = useState<string>(localStorage.getItem(ATTENDANCE_TAB_STATE) ?? ATTENDANCE_TAB_STATE_UPDATE);
    const [loading, setLoading] = useState<boolean>(true);
    const [cohorts, setCohorts] = useState<Cohort[]>([]);
    const [students, setStudents] = useState<User[]>([]);
    const [attendanceUpdate, setAttendanceUpdate] = useState<AttendanceUpdate>({
        day: dayjs(day) ?? dayjs(new Date()),
        addAttendances: [],
        removeAttendances: [],
        cohortId: cache.keys[ATTENDANCE_CACHE_COHORT_ID] !== undefined ? Number(cache.keys[ATTENDANCE_CACHE_COHORT_ID]) : undefined
    });
    
    useEffect(() => {
        cache.update(
            { key: PARAMETER_ATTENDANCE_DAY, value: dayjs(attendanceUpdate.day).format('YYYY-MM-DD') }
        );
    }, [cache, attendanceUpdate]);

    useEffect(() => {
        setLoading(true);
        const getUserStudentsCall = getUserStudents();
        const getCohortsCall = getCohorts();

        Promise.all([getUserStudentsCall, getCohortsCall]).then((result) => {
            setStudents(result[0].filter(_ => _.cohortId !== undefined && _.active === true));
            setCohorts(result[1]);
            setLoading(false);
        });
    }, []);

    const changeTab = (newTab: string) => {
        localStorage.setItem(ATTENDANCE_TAB_STATE, newTab);
        setTab(newTab);
    }

    return (
        <React.Fragment>
            <ViewLoader loading={loading} />
            {loading === false && <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(event: React.SyntheticEvent, newTab: string) => changeTab(newTab)}>
                        <Tab label={"Attendance"} value={ATTENDANCE_TAB_STATE_UPDATE} />
                    </TabList>
                </Box>
                <TabPanel value={ATTENDANCE_TAB_STATE_UPDATE}>
                    <AttendanceHandler
                        attendanceUpdate={attendanceUpdate}
                        setAttendanceUpdate={setAttendanceUpdate}
                        students={students}
                        cohorts={cohorts}
                    />
                </TabPanel>
            </TabContext>}
        </React.Fragment>
    );
}
