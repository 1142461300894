import { GridColDef } from "@mui/x-data-grid";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { renderDate } from "../../../../utils/table-column-renders";

export const columns: GridColDef[] = [
    { field: 'submitDate', headerName: 'Submitted', flex: 1, valueFormatter: (params) => { return renderDate(params.value, true) } },
    { field: 'surveyTitle', headerName: 'Survey', flex: 1.5 },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'emailAddress', headerName: 'E-mail address', flex: 1 },
    { field: 'phoneNumber', headerName: 'Phone number', flex: 1 },
    { field: 'isAccepted', headerName: 'Accepted', flex: 0.5, renderCell: (params) => { return params.row.isAccepted === true ? <CheckCircleIcon color="primary" /> : '' }}
];