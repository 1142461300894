import { User } from "../model/user";
import { requestOptions, HttpMethod } from "../../../utils/api/request-options";
import { handleResponse, handleResponseNoContent } from "../../../utils/api/response-handler";

export function getUserApplicants(): Promise<User[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/applicant/actor/list`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<User[]>(response)
        });
}

export function getUserApplicant(applicantUserId: number): Promise<User> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/applicant/actor/${applicantUserId}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<User>(response)
        });
}

export function addUserApplicant(user: User): Promise<User> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/applicant/actor`, requestOptions(HttpMethod.POST, user))
        .then(response => {
            return handleResponse<User>(response)
        });
}

export function updateUserApplicant(user: User): Promise<User> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/applicant/actor`, requestOptions(HttpMethod.PUT, user))
        .then(response => {
            return handleResponse<User>(response)
        });
}

export function deleteUserApplicant(userId: number): Promise<void> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/applicant/actor/${userId}`, requestOptions(HttpMethod.DELETE))
        .then(response => {
            return handleResponseNoContent(response)
        });
}
