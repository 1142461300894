import React, { useEffect, useState } from "react";
import { BaseList } from "../../../../../component/list/base-list";
import { DashboardManagerFilter } from "../../../model/dashboard-manger-filter";
import { getCohortResubmitBadges } from "../../../service/dashboard-manager-service";
import { CohortStudentModuleBadgeResubmit } from "../../../model/cohort-student-module-badge-resubmit";
import { columns } from "./cohort-student-module-badge-resubmit-list-column-definition";
import BaseOperations from "../../../../../component/operations/base-operations";

const storageKey = "DASHBOARD_MANAGER_RESUBMIT"

export default function CohortStudentModuleBadgeResubmitList({
    filter,
    setFilter
}: {
    filter: DashboardManagerFilter,
    setFilter: React.Dispatch<React.SetStateAction<DashboardManagerFilter>>,
}) {
    const [loading, setLoading] = useState(false);
    const [resubmits, setResubmits] = useState<CohortStudentModuleBadgeResubmit[]>([]);

    useEffect(() => {
        if (filter.cohortId !== undefined) {
            setLoading(true);
            getCohortResubmitBadges(
                filter.cohortId,
                Number(filter.moduleId) !== 0 ? filter.moduleId : undefined,
                filter.studentId !== 0 ? filter.studentId : undefined
            ).then(result => {
                setResubmits(result ?? []);
                setLoading(false);
            });
        } else {
            setResubmits([]);
        }
    }, [filter]);

    return (
        <React.Fragment>
            <BaseList
                storageKey={storageKey}
                style={{ marginBottom: "1.5rem" }}
                rows={resubmits}
                columns={columns}
                loading={loading}
                localeText={{ noRowsLabel: "No resubmit badges" }}
                getRowId={(row) => `${row?.cohortId}-${row?.badgeId}-${row?.studentId}`}
                disableRowSelectionOnClick={false}
                onRowClick={(e) => setFilter({ ...filter, moduleId: e.row?.moduleId, studentId: e.row?.studentId })}
                exportName={`${storageKey}_${filter.cohortId}`}
            />
            {resubmits?.length > 0 && <BaseOperations
                helpInfoTexts={[
                    "You can click on a row in the list to select the <b>Module</b> and <b>Student</b>.",
                    "A badge is included if it <b>can be resubmitted</b> from its current evaluated submission.",
                    "A badge is included if it <b>has been resubmitted</b> from ealier submissions.",
                ]}
            />}
        </React.Fragment>
    );
}
