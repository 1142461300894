import { CourseLevel, courseLevels } from "../section/-education/enum/course-level";
import { ModuleCategory, moduleCategories } from "../section/-education/enum/module-category";
import { SelectItem } from "../component/form/select-item";
import { Gender, groupGenders, individualGenders } from "../section/enum/gender";
import { Role, roles, studentRoles } from "../section/enum/role";

export const groupGenderOptions = () => {
    return groupGenders.map(_ => {
        let selectItem: SelectItem = {
            id: _,
            name: Gender[_].replace('_', ' ')
        }
        return selectItem;
    });
}

export const individualGenderOptions = () => {
    return individualGenders.map(_ => {
        let selectItem: SelectItem = {
            id: _,
            name: Gender[_].replace('_', ' ')
        }
        return selectItem;
    });
}

export const courseLevelOptions = (withAll?: boolean | undefined) => {
    return defaultOptions(withAll).concat(courseLevels.map(_ => {
        let selectItem: SelectItem = {
            id: _,
            name: CourseLevel[_].replace('_', ' ')
        }
        return selectItem;
    }));
}

export const moduleCategoryOptions = (withAll?: boolean | undefined) => {
    return defaultOptions(withAll).concat(moduleCategories.map(_ => {
        let selectItem: SelectItem = {
            id: _,
            name: ModuleCategory[_].replace('_', ' ')
        }
        return selectItem;
    }));
}

export const userRoleOptions = () => {
    return roles.map(_ => {
        let selectItem: SelectItem = {
            id: _,
            name: Role[_].replace('_', ' ')
        }
        return selectItem;
    });
}

export const studentRoleOptions = () => {
    return studentRoles.map(_ => {
        let selectItem: SelectItem = {
            id: _,
            name: Role[_].replace('_', ' ')
        }
        return selectItem;
    });
}

const defaultOptions = (withAll?: boolean | undefined) => {
    const options: SelectItem[] = []
    if (withAll === true) {
        options.push({ id: -1, name: 'All' } as SelectItem)
    }
    return options
}